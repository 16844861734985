// Chartjs
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend, Colors } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import autocolors from 'chartjs-plugin-autocolors';

// utils
import formatAsPercentage from '../../Util/StringFormatPercentage';

// Extracts a time series from the raw JSON
function extractTS(raw, field) {
  return raw.map((p) => ({ x: p[field], y: p.percentage }));
}

// Find the largest X value in a time series
function findMaxX(ts) {
  let maxX = ts[0].x;
  for (let point of ts) {
    if (point.x > maxX) maxX = point.x;
  }
  return maxX;
}

// Render a PFS chart
function PfsKMChart({ data, trial, selectedHistoricalTrials, selectedScenarioTrials, endpoint }) {
  if (!data || !data.KMCurve) {
    return null;
  }

  ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Filler, Colors, autocolors);

  const options = {
    maintainAspectRatio: false,
    showLine: true,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Percentage of Patients',
        },
        beginAtZero: true,
        ticks: {
          // Format as percentage
          callback: function (value, index, ticks) {
            return formatAsPercentage(value);
          },
        },
      },
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          filter: (item) => !item.text.includes('High') && !item.text.includes('Low'),
        },
        overflow: 'auto',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.x !== null) {
              label += context.parsed.x + ' months, ';
            }
            if (context.parsed.y !== null) {
              label += formatAsPercentage(context.parsed.y) + ' of patients';
            }
            return label;
          },
        },
      },
      autocolors: {
        mode: 'dataset',
      },
    },
  };

  // extract time series
  const interventionTS = extractTS(data.KMCurve.intervention, 'medianMonths');
  const interventionHighTS = extractTS(data.KMCurve.intervention, 'highMonths');
  const interventionLowTS = extractTS(data.KMCurve.intervention, 'lowMonths');

  const comparatorTS = extractTS(data.KMCurve.comparator, 'medianMonths');
  const controlHighTS = extractTS(data.KMCurve.comparator, 'highMonths');
  const controlLowTS = extractTS(data.KMCurve.comparator, 'lowMonths');

  const historicalTrialsTS = [];
  if (selectedHistoricalTrials) {
    selectedHistoricalTrials.forEach((trialData) => {
      trialData.trialArms.forEach((trialArm) => {
        const data = trialArm?.[endpoint]?.map((datapoint) => ({
          x: datapoint.time,
          y: datapoint.survival,
        }));
        const dataset = {
          label: `${trialData.trialName} - ${trialArm.name} - ${trialData.cohort} (historical)`,
          data: data,
        };
        historicalTrialsTS.push(dataset);
      });
    });
  }

  const selectedScenarioTrialsTS = [];
  if (selectedScenarioTrials) {
    selectedScenarioTrials.forEach((selectedScenario) => {
      const comparatorData = selectedScenario?.outputs?.[endpoint]?.KMCurve?.comparator.map((datapoint) => ({
        x: datapoint.medianMonths,
        y: datapoint.percentage,
      }));
      const comparatorDataset = {
        label: `${selectedScenario.trialName} - ${selectedScenario.scenarioName} - comparator`,
        data: comparatorData,
      };
      selectedScenarioTrialsTS.push(comparatorDataset);

      const interventionData = selectedScenario?.outputs?.[endpoint]?.KMCurve?.intervention.map((datapoint) => ({
        x: datapoint.medianMonths,
        y: datapoint.percentage,
      }));
      const interventionDataset = {
        label: `${selectedScenario.trialName} - ${selectedScenario.scenarioName} - intervention`,
        data: interventionData,
      };
      selectedScenarioTrialsTS.push(interventionDataset);
    });
  }

  let interventionName = trial?.intervention || 'Intervention';
  let comparatorName = trial?.comparator || 'Comparator';

  // build the chart data
  const chartData = {
    datasets: [],
  };

  // If we are not comparing with other trials, give the expected KM Curve
  if (
    (!selectedHistoricalTrials || selectedHistoricalTrials.length < 1) &&
    (!selectedScenarioTrials || selectedScenarioTrials.length < 1)
  ) {
    chartData.datasets = [
      {
        label: interventionName,
        data: interventionTS,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1)',
      },
      {
        label: interventionName + ' High',
        data: interventionHighTS,
        borderColor: 'rgb(53, 162, 235, 0.2)',
        backgroundColor: 'rgba(53, 162, 235, 0.2)',
      },
      {
        label: interventionName + ' Low',
        data: interventionLowTS,
        borderColor: 'rgb(53, 162, 235, 0.2)',
        backgroundColor: 'rgba(53, 162, 235, 0.2)',
        fill: 1,
      },
      {
        label: comparatorName,
        data: comparatorTS,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132, 1)',
      },
      {
        label: comparatorName + ' Low',
        data: controlLowTS,
        borderColor: 'rgb(255, 99, 132, 0.2)',
        backgroundColor: 'rgb(255, 99, 132, 0.2)',
      },
      {
        label: comparatorName + ' High',
        data: controlHighTS,
        borderColor: 'rgb(255, 99, 132, 0.2)',
        backgroundColor: 'rgb(255, 99, 132, 0.2)',
        fill: 4,
      },
    ];
  } else {
    // Median lines for current scenario
    chartData.datasets.push(
      {
        label: interventionName,
        data: interventionTS,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 1)',
      },

      {
        label: comparatorName,
        data: comparatorTS,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132, 1)',
      },
    );

    // Add historical trials
    if (historicalTrialsTS.length > 0) {
      historicalTrialsTS.forEach((timeseries) => {
        chartData.datasets.push(timeseries);
      });
    }

    // Add scenario trials
    if (selectedScenarioTrialsTS.length > 0) {
      selectedScenarioTrialsTS.forEach((timeseries) => {
        chartData.datasets.push(timeseries);
      });
    }
  }

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Scatter options={options} data={chartData} />
    </div>
  );
}

export default PfsKMChart;
