import { Link } from 'react-router-dom';
import styled from 'styled-components';

// components
import SponsorLogos from './SponsorLogos';

function CheckMark({ score }) {
  if (score === 1) return <i className="bi bi-check-circle-fill sleuthGreen"></i>;
  else if (score === 0) return <i className="bi bi-check-circle-fill sleuthYellow"></i>;

  return <></>;
}

const SimiliarTrialContainer = styled.div``;

// Displays the list of similar trials
function SimilarTrials({ trial }) {
  if (trial && trial.similarTrials && trial.similarTrials.length > 0) {
    return (
      <SimiliarTrialContainer>
        <div className="card sleuthSize">
          <div className="card-header">
            <b>Similar Trials</b>
          </div>
          <div className="card-body">
            <p>Based on the characteristics of this trial, these trials have been identified as being similar.</p>
            <table className="table table-sm mt-2 mb-1">
              <thead className="sleuthSize">
                <tr>
                  <th scope="row" className="text-center">
                    Sponsor
                  </th>
                  <th scope="row" className="text-center">
                    Trial
                  </th>
                  <th scope="row" className="text-center">
                    Indication
                  </th>
                  <th scope="row" className="text-center">
                    Drug
                  </th>
                  <th scope="row" className="text-center">
                    Design
                  </th>
                  <th scope="row" className="text-center">
                    Timing
                  </th>
                </tr>
              </thead>
              <tbody>
                {trial.similarTrials.map((t, i) => (
                  <tr key={'st_' + i}>
                    <td scope="row" className="text-center">
                      <SponsorLogos sponsors={t.sponsor} height={25} />
                    </td>
                    <td scope="row" className="text-center">
                      {t.trialName}
                    </td>
                    <td scope="row" className="text-center">
                      <CheckMark score={t.similarIndication} />
                    </td>
                    <td scope="row" className="text-center">
                      <CheckMark score={t.similarIntervention} />
                    </td>
                    <td scope="row" className="text-center">
                      <CheckMark score={t.similarTrialDesign} />
                    </td>
                    <td scope="row" className="text-center">
                      <CheckMark score={t.similarTiming} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="row">
              <div className="col text-end">
                <div className="text-right sleuthSize">
                  <i className="bi bi-check-circle-fill sleuthGreen text-right"></i> Full Match
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-end">
                <div className="text-right sleuthSize">
                  <i className="bi bi-check-circle-fill sleuthYellow"></i> Partial Match
                </div>
              </div>
            </div>
            <Link to={`/compare/${trial.trialName}`} className="btn btn-warning sleuthActionButton">
              Compare These Trials
            </Link>
          </div>
        </div>
      </SimiliarTrialContainer>
    );
  }
}

export default SimilarTrials;
