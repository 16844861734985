import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// components
import TrialHeader from '../Components/Sub/TrialHeader';
import SimilarTrials from '../Components/Sub/SimilarTrials';

// contexts
import { GlobalDataContext } from '../Contexts/GlobalDataContext.js';

const TrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

// Trial information page, shows all information about a trial.
function TrialView() {
  const { currentTrial } = useContext(GlobalDataContext);

  return (
    currentTrial && (
      <TrialContainer>
        <>
          <TrialHeader trial={currentTrial} />
        </>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-12 p-2">
                {/*  Trial Details */}
                <div className="card sleuthSize">
                  <div className="card-header">
                    <b>Trial Details</b>
                  </div>
                  <div className="card-body">
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <td colSpan="2">
                            <p>{currentTrial.briefSummary}</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Phase
                          </th>
                          <td>{currentTrial.phase}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Status
                          </th>
                          <td>{currentTrial.status}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Indication
                          </th>
                          <td>{currentTrial.indication}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Treatment Line
                          </th>
                          <td>{currentTrial.treatmentLine}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Intervention
                          </th>
                          <td>{currentTrial.intervention}</td>
                        </tr>
                        {currentTrial.interventionModality && currentTrial.interventionModality !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Intervention Modality
                            </th>
                            <td>{currentTrial.interventionModality}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {currentTrial.priorTrials && currentTrial.priorTrials !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Prior Trial(s)
                            </th>
                            <td>{currentTrial.priorTrials}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {currentTrial.interventionEfficacy && currentTrial.interventionEfficacy !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Efficacy (Intervention Arm)
                            </th>
                            <td>{currentTrial.interventionEfficacy}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {currentTrial.interventionSafety && currentTrial.interventionSafety !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Safety (Intervention Arm)
                            </th>
                            <td>{currentTrial.interventionSafety}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {currentTrial.interventionPriorTreatments && currentTrial.interventionPriorTreatments !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Prior Treatments (Intervention Arm)
                            </th>
                            <td>{currentTrial.interventionPriorTreatments}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Comparator
                          </th>
                          <td>{currentTrial.comparator}</td>
                        </tr>
                        {currentTrial.comparatorEfficacy && currentTrial.comparatorEfficacy !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Efficacy (Comparator Arm)
                            </th>
                            <td>{currentTrial.comparatorEfficacy}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        {currentTrial.biomarker && currentTrial.biomarker !== '' ? (
                          <tr>
                            <th scope="row" className="text-end text-nowrap">
                              Biomarker
                            </th>
                            <td>{currentTrial.biomarker}</td>
                          </tr>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Enrollment
                          </th>
                          <td>{currentTrial.enrollment}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Sites
                          </th>
                          <td>{currentTrial.sites}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Primary Outcome
                          </th>
                          <td>{currentTrial.primaryOutcome}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Secondary Outcome
                          </th>
                          <td>{currentTrial.secondaryOutcome}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Estimated Readout
                          </th>
                          <td>{currentTrial.estimatedReadoutDate}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Actual Readout
                          </th>
                          <td>{currentTrial.actualReadoutDate}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="text-end text-nowrap">
                            Masking
                          </th>
                          <td>{currentTrial.masking}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer sleuthSize">
                    Data sourced from{' '}
                    <a href={'https://clinicaltrials.gov/study/' + currentTrial.nctId} target="new">
                      <img
                        src={`/img/logos/ctg_logo.png`}
                        style={{ width: '100px' }}
                        className="img-fluid rounded-start m-0"
                        alt="..."
                      />
                    </a>
                    , medical journals, conference abstracts, corporate documents and more
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 justify-content-center">
            <div className="row">
              <div className="col-12 p-2">
                <div className="card sleuthSize">
                  <div className="card-header">
                    <b>Simulate This Trial</b>
                  </div>
                  <div className="card-body">
                    {!currentTrial.sliders || currentTrial.sliders.length === 0 ? (
                      <>
                        <p>
                          This trial is not included in your current subscription. Contact your Sleuth representative to
                          add it to your account.
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          This trials has not completed, but you can explore potential outcomes through the Sleuth
                          Simulation<sup>TM</sup> platform.
                        </p>
                        <Link
                          to={`/scenarioBuilder/${currentTrial.trialName}`}
                          className="btn btn-warning sleuthActionButton"
                        >
                          Explore Potential Outcomes
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 p-2">
                {/** <SimulationConfig trial={currentTrial} /> **/}
                <SimilarTrials trial={currentTrial} />
              </div>
            </div>
          </div>
        </div>
      </TrialContainer>
    )
  );
}

export default TrialView;
