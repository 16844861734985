import React, { useState, useContext } from 'react';
import { InputGroup, Button, Card, Elevation, HTMLTable, Intent, Tag, Colors, Icon } from '@blueprintjs/core';
import { Link, Navigate } from 'react-router-dom';

import styled from 'styled-components';
import NetworkSettings from '../Hooks/NetworkSettings';
import { UserContext } from '../Contexts/UserContext';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const API_URL = NetworkSettings.SERVER_URL;

const AISearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const StyledCard = styled(Card)`
  transition: all 0.3s ease-in-out;

  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 2px;
`;

const PromptText = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #107e4a;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StyledInputGroup = styled(InputGroup)`
  flex-grow: 1;
  margin-right: 10px;

  & .bp5-input {
    border-radius: 2px;
    border-top: 2px solid ${Colors.GREEN3};
    height: 40px;
  }
`;

const TableContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  z-index: 5;
`;

const ResultsTable = styled(HTMLTable)`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ScrollableTableContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 15px; // Space for the scrollbar
`;

const AIPromptContainer = styled.div``;

const PromptSubtext = styled.p`
  font-size: 16px;
  color: #666;
`;

const PromptOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 18px;
`;

const PromptOption = styled.div`
  flex: 1;
  font-size: 14px;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;

  position: relative;
  min-height: 120px;
`;

const PromptIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

const TrialLink = styled(Link)`
  color: ${Colors.TURQUOISE3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// -------------------------------------------------------------------------------------------------

// For column names
const formatColumnName = (columnName) => {
  if (columnName === 'nct_id') {
    return 'NCT ID';
  }
  return columnName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// For null values
const formatCellValue = (value) => {
  if (value === null || value === 'None' || value === 'N/A') {
    return 'N/A';
  }
  return value;
};

// For trial status
const getStatusColor = (status) => {
  if (!status) return Intent.NONE;
  switch (status.toLowerCase()) {
    case 'completed':
      return Intent.SUCCESS;
    case 'recruiting':
      return Intent.PRIMARY;
    case 'active, not recruiting':
      return Intent.WARNING;
    case 'terminated':
      return Intent.DANGER;
    default:
      return Intent.NONE;
  }
};

// For dates
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

// Specialty rendering
const renderCell = (column, value) => {
  switch (column) {
    case 'trial_name':
      return <TrialLink to={`/trials/${encodeURIComponent(value)}`}>{value}</TrialLink>;

    case 'nct_id':
      return (
        <>
          <a href={`https://clinicaltrials.gov/ct2/show/${value}`} target="_blank" rel="noopener noreferrer">
            {value} <Icon icon="link" />
          </a>
        </>
      );
    case 'trial_status':
      if (value === null || value === undefined) {
        return 'N/A';
      }
      return <Tag intent={getStatusColor(value)}>{value}</Tag>;

    case 'enrollment_actual':
      return <span>{typeof value === 'number' ? value.toLocaleString() : 'N/A'}</span>;
    case 'actual_start_date':
    case 'actual_readout_date':
      return <span>{formatDate(value)}</span>;
    default:
      return formatCellValue(value);
  }
};

// -------------------------------------------------------------------------------------------------

function AISearchView() {
  const [query, setQuery] = useState('');
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasAsked, setHasAsked] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState({});

  const handleQuerySubmit = async () => {
    if (!query.trim()) return;

    setHasAsked(true);
    setLoading(true);
    setError(null);
    setTableData(null);

    try {
      const response = await fetch(`${API_URL}/ai-query/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: query }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setTableData(data.results); // Set tableData to data.results
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePromptClick = (prompt) => {
    setQuery(prompt);
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  // Render the table
  const renderTable = (loading) => {
    if (loading) {
      return <TableRowsLoader rowsNum={10} />;
    }
    if (!tableData) {
      return <div> </div>;
    }

    // Define the order of columns you want
    const columnOrder = [
      'trial_name',
      'nct_id',
      'trial_title_official',
      'trial_status',
      'trial_purpose',
      'sponsor',
      'phase',
      'indications',
      'drugs',
      'enrollment_actual',
      'actual_start_date',
      'actual_readout_date',
      'primary_endpoints',
      'secondary_endpoints',
      'met_primary_endpoint',
      'efficacy_data',
      'safety_data',
    ];

    // Ensure all columns from the data are included, even if not in columnOrder
    const columns = [...new Set([...columnOrder, ...Object.keys(tableData[0])])];
    // Initialize visibleColumns if it's empty
    if (Object.keys(visibleColumns).length === 0) {
      const initialVisibleColumns = columns.reduce((acc, column) => {
        acc[column] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibleColumns);
    }
    const formattedColumns = columns.map(formatColumnName);

    return (
      <>
        <div style={{ marginBottom: '24px' }}>
          {columns.map((column) => (
            <Button
              minimal
              intent="primary"
              outlined
              key={column}
              active={visibleColumns[column] !== false}
              onClick={() => toggleColumnVisibility(column)}
              style={{ marginRight: '8px', marginBottom: '8px' }}
            >
              {formatColumnName(column)}
            </Button>
          ))}
        </div>

        <ResultsTable striped bordered>
          <thead>
            <tr>
              {formattedColumns.map(
                (column, index) => visibleColumns[columns[index]] !== false && <th key={index}>{column}</th>,
              )}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(
                  (column, columnIndex) =>
                    visibleColumns[column] !== false && <td key={columnIndex}>{renderCell(column, row[column])}</td>,
                )}
              </tr>
            ))}
          </tbody>
        </ResultsTable>
      </>
    );
  };

  // Skeleton for loading
  const TableRowsLoader = ({ rowsNum }) => {
    return [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton variant="rounded" width={100} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" width={210} height={40} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" width={80} height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" width={150} height={50} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" width={200} height={30} />
        </TableCell>
        <TableCell>
          <Skeleton variant="rounded" width={90} height={20} />
        </TableCell>
      </TableRow>
    ));
  };

  const { organizationId } = useContext(UserContext);

  // If the user is not in the allowed WOS organizations, redirect to the home page
  if (NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS !== organizationId) {
    return <Navigate to="/" replace />;
  }

  return (
    <AISearchContainer className="aisearch-container">
      <AIPromptContainer>
        <StyledCard elevation={Elevation.TWO}>
          <PromptText>What would you like to know?</PromptText>
          <PromptSubtext>
            Ask about any trial, drug, or sponsor. Or use the sample prompts below to get started.
          </PromptSubtext>

          <PromptOptionsContainer>
            <PromptOption onClick={() => handlePromptClick('Generate a competitive landscape for {asset/trial}')}>
              Generate a competitive landscape for Fulvestrant
              <PromptIcon icon="bullseye" />
            </PromptOption>
            <PromptOption
              onClick={() =>
                handlePromptClick('Show me a comparison of {company X} and {company Y}’s {asset} portfolios')
              }
            >
              Show me a comparison of Merck's and Pfizer's oncology portfolios
              <PromptIcon icon="folder-open" />
            </PromptOption>
            <PromptOption onClick={() => handlePromptClick('What {assets/trials} are similar to {asset/trial}?')}>
              What trials are similar to VIKTORIA-1?
              <PromptIcon icon="lab-test" />
            </PromptOption>
          </PromptOptionsContainer>

          <InputContainer>
            <StyledInputGroup
              large
              placeholder="Enter your question here..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleQuerySubmit();
                }
              }}
            />
            <Button large intent="primary" onClick={handleQuerySubmit} disabled={loading} icon="clean">
              Ask AI
            </Button>
          </InputContainer>
        </StyledCard>
      </AIPromptContainer>

      <ScrollableTableContainer>
        <TableContainer>{renderTable(loading)}</TableContainer>
      </ScrollableTableContainer>
    </AISearchContainer>
  );
}

export default AISearchView;
