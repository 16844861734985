import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'react-chatbot-kit/build/main.css';
import './Components/Chatbot.css';

import { initializeToaster } from './Util/Toaster';

// components
import WorkOSLoginView from './Pages/WorkOSLoginView.js';

import SearchView from './Pages/SearchView.js';
import HomeView from './Pages/HomeView';
import MonitoringEmailView from './Pages/MonitoringEmailView.js';
import TrialView from './Pages/TrialView';
import TrialSearchView from './Pages/TrialSearchView';
import TrialCompareView from './Pages/TrialCompareView';
import TrialsView from './Pages/TrialsView';

import ScenarioBuilderView from './Pages/ScenarioBuilderView.js';
import ProgressView from './Pages/ProgressView.js';
import ScenarioSummaryView from './Pages/ScenarioSummaryView.js';
import ScenarioResultsView from './Pages/ScenarioResultsView.js';

import DrugsView from './Pages/DrugsView.js';
import DrugSearch from './Pages/DrugSearchView.js';
import KnowledgeView from './Pages/KnowledgeView.js';
import CompetitiveIntelligenceViewNew from './Pages/CompetitiveIntelligenceViewNew.js';
import IntelligenceSearch from './Components/IntelligenceSearch';
import ProjectView from './Pages/ProjectView.js';

import AISandboxView from './Pages/AISandboxView.js';

// Autonomous AI working
import AutonomousAIView from './Pages/AutonomousAIView.js';
import AutonomousAINewTaskView from './Pages/AutonomousAINewTaskView.js';
import AutonomousAIWorkspace from './Pages/AutonomousAIWorkspaceView.js';
import AutonomousAIRepositoryView from './Pages/AutonomousAIRepositoryView.js';
import AutonomousAIDeliverableView from './Pages/AutonomousAIDeliverableView.js';

// Autonomous AI demo
import AutonomousAIViewDemo from './Pages/AutonomousAIViewDemo.js';
import AutonomousAINewTaskViewDemo from './Pages/AutonomousAINewTaskViewDemo.js';
import AutonomousAIWorkspaceDemo from './Pages/AutonomousAIWorkspaceViewDemo.js';
import AutonomousAIPipelineView from './Pages/AutonomousAIPipelineViewDemo.js';
import AutonomousAIRepositoryViewDemo from './Pages/AutonomousAIRepositoryViewDemo.js';

import Layout from './Pages/Layout.js';
import ProtectedRoute from './Components/ProtectedRoute.js';
import NotFoundView from './Pages/NotFoundView.js';
import { GlobalDataContextProvider } from './Contexts/GlobalDataContext.js';

import styled from 'styled-components';

const AppWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

function App() {
  useEffect(() => {
    initializeToaster();
  }, []);

  return (
    <AppWrapper>
      <Router>
        <GlobalDataContextProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <HomeView />
                  </ProtectedRoute>
                }
              />

              <Route path="/login" element={<WorkOSLoginView />} />
              <Route
                path="/search"
                element={
                  <ProtectedRoute>
                    <SearchView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/knowledge"
                element={
                  <ProtectedRoute>
                    <KnowledgeView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/projects"
                element={
                  <ProtectedRoute>
                    <ProjectView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/intelligence"
                element={
                  <ProtectedRoute>
                    <CompetitiveIntelligenceViewNew />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/intel-search"
                element={
                  <ProtectedRoute>
                    <IntelligenceSearch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drugs"
                element={
                  <ProtectedRoute>
                    <DrugSearch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/drugs/:drugUUID"
                element={
                  <ProtectedRoute>
                    <DrugsView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/trials"
                element={
                  <ProtectedRoute>
                    <TrialSearchView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/trial/:trialName"
                element={
                  <ProtectedRoute>
                    <TrialView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/trials/:trialName"
                element={
                  <ProtectedRoute>
                    <TrialsView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/compare/:trialName"
                element={
                  <ProtectedRoute>
                    <TrialCompareView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scenarioBuilder/:trialName"
                element={
                  <ProtectedRoute>
                    <ScenarioBuilderView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/progress"
                element={
                  <ProtectedRoute>
                    <ProgressView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scenarioSummary"
                element={
                  <ProtectedRoute>
                    <ScenarioSummaryView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scenarioResults/:scenarioID"
                element={
                  <ProtectedRoute>
                    <ScenarioResultsView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/ai-sandbox"
                element={
                  <ProtectedRoute>
                    <AISandboxView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/monitoring"
                element={
                  <ProtectedRoute>
                    <MonitoringEmailView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai"
                element={
                  <ProtectedRoute>
                    <AutonomousAIView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/workspace"
                element={
                  <ProtectedRoute>
                    <AutonomousAIWorkspace />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/create-task"
                element={
                  <ProtectedRoute>
                    <AutonomousAINewTaskView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/repository"
                element={
                  <ProtectedRoute>
                    <AutonomousAIRepositoryView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/repository/:deliverableID"
                element={
                  <ProtectedRoute>
                    <AutonomousAIRepositoryView />
                  </ProtectedRoute>
                }
              />

              <Route path="/autonomous-ai/deliverables/:deliverableID" element={<AutonomousAIDeliverableView />} />

              <Route
                path="/ai-agents"
                element={
                  <ProtectedRoute>
                    <AutonomousAIViewDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents/create-task"
                element={
                  <ProtectedRoute>
                    <AutonomousAINewTaskViewDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents/pipeline"
                element={
                  <ProtectedRoute>
                    <AutonomousAIPipelineView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents/workspace"
                element={
                  <ProtectedRoute>
                    <AutonomousAIWorkspaceDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents/repository"
                element={
                  <ProtectedRoute>
                    <AutonomousAIRepositoryViewDemo />
                  </ProtectedRoute>
                }
              />

              {/* Redirect all unknown routes to 404 */}
              <Route path="/404" element={<NotFoundView />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
          </Routes>
        </GlobalDataContextProvider>
      </Router>
    </AppWrapper>
  );
}

export default App;
