import React, { useState, useContext } from 'react';
import { Card, Colors, Icon, InputGroup, Button, MenuItem, FormGroup } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import styled from 'styled-components';

import { UserContext } from '../Contexts/UserContext';
import NetworkSettings from '../Hooks/NetworkSettings';
import { showToast } from '../Util/Toaster.js';

const API_URL = NetworkSettings.SERVER_URL;

const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 24px;
  gap: 12px;

  & .bp5-form-group {
    margin-bottom: 0px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px 24px 24px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
`;

const CardTitle = styled.h2`
  font-size: 1.2rem;
  color: ${Colors.GREEN3};
`;

const CreateTaskCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 900px;
  min-height: 500px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & .bp5-form-group {
    margin-bottom: 0px;
  }
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const SourcesSelect = styled(MultiSelect)``;

const SourcesSelectContainer = styled.div`
  & .bp5-input {
    height: 40px !important;
  }
`;

const StyledInputGroup = styled(InputGroup)`
  flex-grow: 1;

  & .bp5-input {
    height: 40px !important;
  }
`;

const TaskIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

const TaskMenuItem = styled.div`
  flex: 0 0 calc(33.333% - 8px);
  font-size: 14px;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  position: relative;
  height: 120px;

  /* Hover and selected styles */
  transition: all 0.2s ease-in-out;

  /* Selected state */
  ${(props) =>
    props.$isSelected &&
    `
    background-color: ${Colors.GREEN5};
    color: white;
    border-color: ${Colors.GREEN5};
    
    ${TaskTitle} {
      color: ${Colors.WHITE};
    }
  `}

  /* Hover state (only apply if not selected) */
  &:hover {
    ${(props) =>
      !props.$isSelected &&
      `
      background-color: ${Colors.GREEN3};
      color: white;
      border-color: ${Colors.GREEN3};

      ${TaskTitle} {
        color: ${Colors.WHITE};
      }
    `}
  }
`;

const TaskMenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const TaskTitle = styled.h3`
  font-size: 1rem;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const TaskTypes = [
  { label: 'Build a competitive landscape', value: 'competitive-landscape', icon: 'bullseye' },
  { label: 'Perform portfolio analysis or comparisons', value: 'portfolio-analysis', icon: 'folder-open' },
  { label: 'Create data visualization or graphic', value: 'data-visualization', icon: 'chart' },
  { label: 'Analyze a specific dataset', value: 'analyze-dataset', icon: 'predictive-analysis' },
  { label: 'Answer a specific question', value: 'answer-question', icon: 'data-search' },
  {
    label: 'Benchmark a trial, indiciation, asset, target, or company',
    value: 'benchmark-trial',
    icon: 'search-template',
  },
];

const SourcesOptions = [
  { label: 'PubMed Abstracts', value: 'pubmed-abstracts' },
  { label: 'Corporate Communications', value: 'corporate-communications' },
  { label: 'Corporate Filings & Financial Documents', value: 'corporate-filings-financial-documents' },
  { label: 'Market Intelligence & Expert Insights', value: 'market-intelligence-expert-insights' },
  { label: 'Other Third Parties', value: 'other-third-parties' },
  { label: 'Patents & Legal Documents', value: 'patents-legal-documents' },
  { label: 'Regulatory Briefings and Guidelines', value: 'regulatory-briefings-guidelines' },
  { label: 'Scientific & Clinical Evidence', value: 'scientific-clinical-evidence' },
];

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAINewTaskView = () => {
  const { organizationId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [threadId, setThreadId] = useState(null);

  const [selectedSources, setSelectedSources] = useState([]);

  const handleTaskSubmit = async () => {
    // Check if both are missing
    if (!selectedTaskType && !query) {
      showToast({
        message: 'Please select a task type and enter task details',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      return;
    }

    // Check if only task type is missing
    if (!selectedTaskType) {
      showToast({
        message: 'Please select a task type',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      return;
    }

    // Check if only query is missing
    if (!query) {
      showToast({
        message: 'Please enter task details',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      return;
    }

    // Start the agent
    try {
      const bodyJSON = JSON.stringify({
        query: query,
        task_type: selectedTaskType,
        sources: selectedSources,
      });

      const response = await fetch(`${API_URL}/start-agent/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyJSON,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setThreadId(data.thread_id);
    } catch (error) {
      console.error('Error fetching data:', error);
      showToast({
        message: `Failed to initiate task: ${error.message}`,
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }

    // Reset the form and show success toast
    setQuery('');
    setSelectedTaskType(null);
    setSelectedSources([]);
    showToast({
      message: `Task initiated successfully`,
      intent: 'success',
      icon: 'tick',
      title: 'Elementary!',
    });
  };

  // Handle source item selection for multi-select
  const handleSourceItemSelect = (item) => {
    setSelectedSources((prevSelected) => {
      const currentSelected = prevSelected || [];

      // Check if the item's value is already selected
      const isItemSelected = currentSelected.includes(item.value);

      if (isItemSelected) {
        // If value is already selected, remove it
        return currentSelected.filter((value) => value !== item.value);
      } else {
        // If value is not selected, add it
        return [...currentSelected, item.value];
      }
    });
  };

  // Handle source item tag removal for multi-select
  const handleSourceItemTagRemove = (tag, index) => {
    setSelectedSources((prevSelected) => prevSelected.filter((_, i) => i !== index));
  };

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Create Task</PageTitle>
        <PageSubtitle className="page-subtitle">
          Construct a new task for the autonomous AI agent. You can choose from the variety of task types listed below
          and add more details as well as specific source designations.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <CreateTaskCard className="create-task-card">
          <CardHeader className="card-header">
            <CardTitle className="card-title">Start a new task for Sleuthlock</CardTitle>
            {/* <CardSubtitle className="card-subtitle">
              Select the type of query and then provide context below...
            </CardSubtitle> */}
          </CardHeader>

          <CardBody className="card-body">
            <FormGroup label="Task Type" labelInfo="(required)">
              <TaskMenuItemContainer>
                {TaskTypes.map((task) => (
                  <TaskMenuItem
                    key={task.value + '-task-menu-item'}
                    onClick={() => setSelectedTaskType(task.value)}
                    $isSelected={selectedTaskType === task.value}
                    className="task-menu-item"
                  >
                    <TaskTitle>{task.label}</TaskTitle>
                    <TaskIcon icon={task.icon} />
                  </TaskMenuItem>
                ))}
              </TaskMenuItemContainer>
            </FormGroup>
          </CardBody>

          <CardFooter className="card-footer">
            <InputContainer className="input-container">
              <FormGroup fill label="Task Details" labelInfo="(required)">
                <StyledInputGroup
                  placeholder="Enter task details here..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleTaskSubmit();
                    }
                  }}
                />
              </FormGroup>
            </InputContainer>

            <SourcesSelectContainer className="sources-select-container">
              <FormGroup label="Sources">
                <SourcesSelect
                  items={SourcesOptions || []}
                  itemRenderer={(item, { handleClick, modifiers }) => (
                    <MenuItem key={item.value} text={item.label} onClick={handleClick} active={modifiers.active} />
                  )}
                  onItemSelect={handleSourceItemSelect}
                  tagRenderer={(value) => {
                    const option = SourcesOptions.find((opt) => opt.value === value);
                    return option ? option.label : value;
                  }}
                  selectedItems={selectedSources || []}
                  tagInputProps={{
                    onRemove: handleSourceItemTagRemove,
                  }}
                  placeholder="Select sources to focus on..."
                  resetOnSelect={true}
                  noResults={<MenuItem key="no-results-scenario" disabled={true} text="No items available" />}
                  popoverProps={{ placement: 'bottom', minimal: true, matchTargetWidth: true }}
                />
              </FormGroup>
            </SourcesSelectContainer>

            <Button intent="primary" icon="clean" onClick={handleTaskSubmit} large>
              Initiate Task
            </Button>
          </CardFooter>
        </CreateTaskCard>
      </PageBody>
    </ViewContainer>
  );
};

export default AutonomousAINewTaskView;
