import React, { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  CompoundTag,
  Colors,
  HTMLTable,
  Tag,
  Card,
  AnchorButton,
  Text,
  Button,
  MenuItem,
  Divider,
  Collapse,
} from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import styled from 'styled-components';

// Components
import NetworkSettings from '../Hooks/NetworkSettings';
import SponsorLogos from '../Components/Sub/SponsorLogos';
import ErrorState from './ErrorStateView';

// Utils
import { snakeToCamel } from '../Util/CaseConvert';
import { HTTPError } from '../Util/Errors';
import { showToast } from '../Util/Toaster';

const API_URL = NetworkSettings.SERVER_URL + '/clinical-trial-details-new';
const SEARCH_API_URL = NetworkSettings.SERVER_URL + '/clinical-trial-search-new';
const OVERVIEW_API_URL = NetworkSettings.SERVER_URL + '/clinical-trial-overview';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  background-color: ${Colors.LIGHT_GRAY3};
  padding: 12px;
  border-radius: 2px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardCloseButton = styled(Button)``;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`;

const CardHeading = styled(Text)`
  font-weight: bold;
  font-size: 0.9rem;
  color: ${Colors.GREEN4};
`;

const CardMainBodyTextContainer = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardSubBodyTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CardSubtitle = styled.h6`
  color: ${Colors.GRAY1};
  display: flex;
  margin: 4px 0 8px;
`;

const CardSubtitleDateText = styled(Text)`
  margin-left: 12px;
  color: ${Colors.GRAY3};
`;

const CardText = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a6741;
`;

const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CohortCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  flex: 1;
`;

const CohortCardBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: flex-start;
`;

const CohortTag = styled(Tag)`
  max-width: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const CohortsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const CriteriaButton = styled(Button)`
  background-color: ${Colors.GREEN3};
  color: ${Colors.WHITE};
`;

const DiseaseInfoCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.RED1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.RED3};
  }
`;

const DrugCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.BLUE1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.BLUE3};
  }
`;

const OutcomeSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const OutcomeTag = styled(Tag)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OutcomeText = styled(Text)`
  color: ${Colors.GRAY3};
  width: 100px;
`;

const SimilarTrialsCard = styled(Card)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px 24px 36px;
  flex: 1;
  justify-content: space-between;
`;

const SponsorLogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const StatusCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.GREEN1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.GREEN3};
  }
`;

const StyledTable = styled(HTMLTable)`
  width: 100%;
`;

const SummaryCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  flex: 1.5;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  max-width: 100%;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const TermTag = styled(Tag)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TextProfileLabel = styled(Text)`
  width: 200px;
  font-weight: bold;
  font-size: 0.8rem;
  color: ${Colors.GRAY3};
  text-transform: uppercase;
`;

const TextProfileTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextProfileValue = styled(Text)`
  flex: 1;
  color: ${Colors.DARK_GRAY5};
  hyphens: auto;
`;

const TrialBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TrialCohortContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const TrialDetailsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  flex: 1;
`;

const TrialDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TrialDetailsSuggest = styled(Suggest)`
  width: 100%;

  & .bp5-input {
    border-radius: 2px;
    border-top: 2px solid ${Colors.GREEN3};
    height: 40px;
  }
`;

const TrialHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TrialHeaderNCTNumberAnchorButton = styled(AnchorButton)`
  height: 30px;
  margin: auto 0;
  color: ${Colors.BLACK};
  font-size: 12px;
`;

const TrialLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.TURQUOISE3};
`;

const TrialLinksContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TrialName = styled.h2`
  color: #4a6741;
`;

const TrialOutcomesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
`;

const TrialOverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TrialSubtitle = styled.h6`
  color: ${Colors.GRAY3};
`;

const TrialTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const TrialTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
`;

const TrialTitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// --------------------------------------------------------------------------------------------------

// Format sponsor names
function formatSponsorNames(sponsorNames) {
  if (!sponsorNames) return [];
  if (typeof sponsorNames === 'string') {
    return sponsorNames.split(',').map((sponsorName) => sponsorName.trim());
  }
  return sponsorNames?.map((sponsorName) => {
    if (sponsorName === 'Celcuity') {
      return 'Celcuity';
    }
    return sponsorName;
  });
}

// Format timestamp
const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'N/A';
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

// Render outcome tags
const renderOutcomeTags = (outcomes) => {
  if (!outcomes || outcomes.length === 0) return <Tag minimal>N/A</Tag>;
  return outcomes.map((outcome, index) => (
    <Tag key={`outcome-${outcome}-${index}`} minimal style={{ margin: '2px' }}>
      {outcome}
    </Tag>
  ));
};

// Render trial links
const renderTrialLinks = (trials) => {
  if (!trials || (Array.isArray(trials) && trials.length === 0)) return 'N/A';

  const trialGroups = typeof trials === 'string' ? trials.split(';') : trials;

  return (
    <TrialLinksContainer>
      {trialGroups.map((trialGroup, index) => {
        if (trialGroup == null) return <span key={index}>N/A</span>;

        let trimmedTrialName;
        if (typeof trialGroup === 'string') {
          const [trialName] = trialGroup.split(',');
          trimmedTrialName = trialName.trim();
        } else if (typeof trialGroup === 'object' && trialGroup.trialName) {
          trimmedTrialName = trialGroup.trialName.trim();
        } else {
          return <span key={index}>N/A</span>;
        }

        if (trimmedTrialName.toLowerCase() === 'n/a') {
          return <span key={index}>N/A</span>;
        }

        return (
          <TrialLink key={index} to={`/trials/${encodeURIComponent(trimmedTrialName)}`}>
            {trimmedTrialName}
          </TrialLink>
        );
      })}
    </TrialLinksContainer>
  );
};

function TrialsView() {
  const { trialName } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Basic data
  const [overviewData, setOverviewData] = useState(null);
  const [atomicTrialsData, setAtomicTrialsData] = useState([]);
  const [additionalSimilarTrials, setAdditionalSimilarTrials] = useState([]);

  // Search
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedTrial, setSelectedTrial] = useState(null);
  const [selectedTrialCohorts, setSelectedTrialCohorts] = useState([]);
  const [cohortSelected, setCohortSelected] = useState(false);
  const suggestRef = useRef(null);

  // Table
  const [visibleColumns, setVisibleColumns] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  // Information display
  const [selectedCohort, setSelectedCohort] = useState(null);
  const [showInclusionCriteria, setShowInclusionCriteria] = useState(false);
  const [showExclusionCriteria, setShowExclusionCriteria] = useState(false);

  const navigate = useNavigate();

  const allColumns = [
    'Trial Name',
    'NCT Number',
    'Trial Arm',
    'Trial Time Point',
    'Cohort',
    'Cohort Description',
    'Start Date',
    'End Date',
    'Highest Phase',
    'Status',
    'Drugs',
    'Enrollment',
    'Indication',
    'Primary Outcome',
    'Treatment Line',
    'Trial Efficacy',
    'Trial Safety',
    'Sponsor',
    'Collaborators',
    'Baseline Traits',
    'Dose',
    'Combination Therapy?',
    'Healthy Volunteers?',
    'Met Primary Endpoint?',
    'Similar Trials',
    'Prior Trials',
    'External Links',
  ];
  const additionalColumns = allColumns.slice(2);

  const fetchTrialOverviewData = useCallback(async () => {
    try {
      const response = await fetch(`${OVERVIEW_API_URL}/${trialName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        throw new HTTPError(response.status, response.statusText);
      }

      const results = await response.json();
      if (results && results.trialDetails) {
        const camelCaseResults = snakeToCamel(results.trialDetails);
        setOverviewData(camelCaseResults);
      } else {
        throw new HTTPError(404, 'Trial not found');
      }
    } catch (error) {
      if (error instanceof HTTPError) {
        throw error;
      } else {
        throw new HTTPError(500, 'An unexpected error occurred fetching trial overview data');
      }
    }
  }, [trialName]);

  const fetchAtomicTrialData = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/${trialName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        throw new HTTPError(response.status, response.statusText);
      }
      const results = await response.json();
      if (results && results.atomicTrials) {
        const camelCaseResults = snakeToCamel(results.atomicTrials);
        setAtomicTrialsData(camelCaseResults);
      } else {
        throw new HTTPError(404, 'Atomic trial not found');
      }
    } catch (error) {
      if (error instanceof HTTPError) {
        throw error;
      } else {
        throw new HTTPError(500, 'An unexpected error occurred fetching atomic trial data');
      }
    }
  }, [trialName]);

  const fetchAllData = useCallback(async () => {
    setLoading(true);
    setError(null);
    setOverviewData(null);
    setAtomicTrialsData([]);
    setSelectedCohort(null);
    setSelectedTrial(null);
    setSelectedTrialCohorts([]);
    setAdditionalSimilarTrials([]);
    setSearchResults([]);
    setActiveFilters({});

    try {
      await fetchTrialOverviewData();
      await fetchAtomicTrialData();
    } catch (error) {
      setError(error instanceof HTTPError ? error : new HTTPError(500, 'An unexpected error occurred'));
    } finally {
      setLoading(false);
    }
  }, [fetchTrialOverviewData, fetchAtomicTrialData]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  // Initialize visible columns
  useEffect(() => {
    const initialColumns = [
      'Trial Name',
      'NCT Number',
      'Cohort',
      'Highest Phase',
      'Status',
      'Trial Efficacy',
      'Trial Safety',
      'Sponsor',
      'Trial Arm',
      'Trial Time Point',
      'Drugs',
    ];

    const initialVisibleColumns = allColumns.reduce((acc, column) => {
      acc[column] = initialColumns.includes(column);
      return acc;
    }, {});

    setVisibleColumns(initialVisibleColumns);
  }, []);

  // Toggle column visibility
  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  // Search additional trials to compare against
  const debouncedSearch = useCallback(
    debounce(async (newQuery) => {
      if (newQuery.length < 2) {
        setSearchResults([]);
        return;
      }
      try {
        const response = await fetch(SEARCH_API_URL + `?query=${encodeURIComponent(newQuery)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const results = await response.json();
        const camelCaseResults = snakeToCamel(results);

        // Set the initial search results
        setSearchResults(Array.isArray(camelCaseResults) ? camelCaseResults : [camelCaseResults]);

        // Clear any previously selected trial
        setSelectedTrial(null);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setSearchResults([]);
      }
    }, 300),
    [],
  );

  // Handle trial select
  const handleTrialSelect = async (trial) => {
    try {
      setSelectedTrial(trial);
      const atomicTrialsResponse = await fetch(`${API_URL}/${trial.trialName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!atomicTrialsResponse.ok) {
        if (atomicTrialsResponse.status === 403) {
          showToast({
            message:
              'This trial is not included in your current subscription. Contact your Sleuth representative to add it to your account.',
            intent: 'danger',
            icon: 'error',
            title: 'Erroneous...',
          });
        }
        throw new HTTPError(atomicTrialsResponse.status, atomicTrialsResponse.statusText);
      }
      const atomicTrialsResults = await atomicTrialsResponse.json();
      const camelCaseAtomicTrialsResults = snakeToCamel(atomicTrialsResults.atomicTrials);

      // Ensure atomicTrials is always an array
      const atomicTrials = Array.isArray(camelCaseAtomicTrialsResults)
        ? camelCaseAtomicTrialsResults
        : [camelCaseAtomicTrialsResults];

      setSelectedTrialCohorts(atomicTrials);
      setQuery('');
      if (suggestRef.current) {
        suggestRef.current.setState({ query: '' });
      }
    } catch (error) {
      console.error('Error fetching atomic trials:', error);
      setSelectedTrial(null);
      setSelectedTrialCohorts([]);
    }
  };

  // Handle atomic trial select
  const handleAtomicTrialSelect = (atomicTrial) => {
    setAdditionalSimilarTrials((prev) => [...prev, atomicTrial]);
    setSelectedTrial(null);
    setQuery('');
    setSearchResults([]);
    setCohortSelected(true);
    if (suggestRef.current) {
      suggestRef.current.setState({ query: '' });
    }
  };

  const handleRemoveAdditionalTrial = (trialToRemove) => {
    setAdditionalSimilarTrials((prevTrials) => prevTrials.filter((trial) => trial !== trialToRemove));
  };

  const getFilteredResults = () => {
    let allTrials = [];

    // Add trialData
    if (atomicTrialsData && atomicTrialsData.length > 0) {
      allTrials = allTrials.concat(atomicTrialsData);
    }

    // Add additionalSimilarTrials
    if (Array.isArray(additionalSimilarTrials)) {
      allTrials = allTrials.concat(additionalSimilarTrials);
    }

    return allTrials.filter((trial) => {
      return Object.entries(activeFilters).every(([column, values]) => {
        if (values.length === 0) return true;
        const trialValue = trial[column] || '';
        return values.some((value) => trialValue.includes(value));
      });
    });
  };
  const filteredResults = getFilteredResults();

  if (loading) return <div>Loading...</div>;
  if (error) {
    switch (error.status) {
      case 400:
        return (
          <ErrorState
            error="Bad Request: The server couldn't understand the request."
            action={fetchAllData}
            buttonText="Retry"
          />
        );
      case 401:
        return (
          <ErrorState
            error="Unauthorized: Please log in to access this resource."
            action={fetchAllData}
            buttonText="Retry"
          />
        );
      case 403:
        return (
          <ErrorState
            error={`This trial is not included in your current subscription. Contact your Sleuth representative to
            add it to your account.`}
            action={() => navigate('/trials')}
            buttonText="Back to search"
          />
        );
      case 404:
        return (
          <ErrorState
            error="Trial not found: The requested trial doesn't exist."
            action={() => navigate('/trials')}
            buttonText="Back to search"
          />
        );
      case 500:
        return (
          <ErrorState
            error="Internal Server Error: Something went wrong on our end."
            action={fetchAllData}
            buttonText="Retry"
          />
        );
      default:
        return <ErrorState error={`An error occurred: ${error.message}`} action={fetchAllData} buttonText="Retry" />;
    }
  }

  return (
    <TrialContainer className="trials-container">
      <TrialHeader className="trials-header">
        <TrialTitleContainer className="trial-title-container">
          <TrialTitleTextContainer>
            {overviewData && (
              <TrialName className="trial-name">
                {overviewData.trialName}
                <TrialHeaderNCTNumberAnchorButton
                  minimal
                  href={`https://clinicaltrials.gov/study/${overviewData.nctId}`}
                  className="trial-header-nct-link"
                  rightIcon="share"
                  target="_blank"
                  text={`(${overviewData.nctId})`}
                />
              </TrialName>
            )}
            <TrialSubtitle className="trial-subtitle">{overviewData?.trialTitleOfficial}</TrialSubtitle>
          </TrialTitleTextContainer>

          <SponsorLogosContainer>
            <SponsorLogos sponsors={formatSponsorNames(overviewData?.sponsor)} height={25} />
          </SponsorLogosContainer>
        </TrialTitleContainer>

        <TrialTagsContainer className="trial-tags-container">
          {overviewData?.phase && (
            <StatusCompoundTag key={overviewData?.phase} leftContent="Highest Phase" round large icon="flows">
              {overviewData?.phase}
            </StatusCompoundTag>
          )}

          {overviewData?.drugs && (
            <DrugCompoundTag key={overviewData?.drugs} leftContent="Drugs" round large icon="pill">
              {overviewData?.drugs}
            </DrugCompoundTag>
          )}

          {overviewData?.indications && (
            <DiseaseInfoCompoundTag key={overviewData?.indications} leftContent="Indication" round large icon="virus">
              {overviewData?.indications}
            </DiseaseInfoCompoundTag>
          )}
        </TrialTagsContainer>
      </TrialHeader>

      <TrialBody className="trials-body">
        <TrialOverviewContainer>
          <SummaryCard className="summary-card">
            <CardTitle className="card-title">Overview</CardTitle>
            <CardBody className="card-body">
              {overviewData?.aiSummary && <CardText className="card-text">{overviewData.aiSummary}</CardText>}
              <CardSubtitle className="card-subtitle">Outcomes</CardSubtitle>
              <TrialOutcomesContainer className="trial-outcomes-container">
                <OutcomeSection>
                  <OutcomeText className="outcome-text">Primary</OutcomeText>
                  <OutcomeTag key={'primary-outcome'} intent="primary">
                    {overviewData?.primaryEndpoints}
                  </OutcomeTag>
                </OutcomeSection>
                <OutcomeSection>
                  <OutcomeText>Secondary</OutcomeText>
                  {overviewData?.secondaryEndpoints
                    ?.split(', ')
                    ?.sort()
                    ?.map((outcome, index) => (
                      <OutcomeTag key={outcome + index} intent="secondary">
                        {outcome}
                      </OutcomeTag>
                    ))}
                </OutcomeSection>
              </TrialOutcomesContainer>
              <Divider />
              <CardSubtitle className="card-subtitle">Select a Cohort</CardSubtitle>
              <CohortsContainer>
                {atomicTrialsData &&
                  atomicTrialsData.map((trial) => (
                    <CohortTag
                      key={`cohort-${trial?.cohort}-arm-${trial?.trialArm}-timepoint-${trial?.trialTimePoint}`}
                      minimal
                      intent={selectedCohort === trial ? 'success' : 'none'}
                      onClick={() => setSelectedCohort(trial)}
                    >
                      {`${trial?.cohort || 'N/A'} (${trial?.trialArm || 'N/A'}) - ${trial?.trialTimePoint || 'N/A'}`}
                    </CohortTag>
                  ))}
              </CohortsContainer>
            </CardBody>
          </SummaryCard>

          <SimilarTrialsCard>
            <CardColumn>
              <CardTitle className="card-title">Similar Trials</CardTitle>
              <CardBody className="card-body">{renderTrialLinks(overviewData?.similarTrials)}</CardBody>
            </CardColumn>

            <CardColumn>
              <CardTitle className="card-title">Prior Trials</CardTitle>
              <CardBody className="card-body">{renderTrialLinks(overviewData?.priorTrials)}</CardBody>
            </CardColumn>
          </SimilarTrialsCard>
        </TrialOverviewContainer>

        {selectedCohort && (
          <TrialCohortContainer>
            <CohortCard>
              <CardTitleContainer>
                <CardTitle className="card-title">Cohort Information</CardTitle>
                <CardCloseButton onClick={() => setSelectedCohort(null)} icon="cross" minimal></CardCloseButton>
              </CardTitleContainer>

              <CohortCardBody className="cohort-card-body">
                <CardMainBodyTextContainer>
                  <CardSubtitle className="card-subtitle">
                    {selectedCohort.cohort} ({selectedCohort.cohortDescription}){' '}
                    <CardSubtitleDateText>
                      ({formatTimestamp(selectedCohort.actualStartDate)} -{' '}
                      {formatTimestamp(selectedCohort.actualEndDate) === 'N/A'
                        ? ''
                        : formatTimestamp(selectedCohort.actualEndDate)}
                      )
                    </CardSubtitleDateText>
                    <br />
                  </CardSubtitle>
                  <CardHeading>Purpose</CardHeading>
                  <Text>{selectedCohort.trialPurpose || 'N/A'}</Text>
                  <br />
                  <CardHeading>Cohort Dosage</CardHeading>
                  <Text>{selectedCohort.cohortDose || 'N/A'}</Text>
                  <br />
                  <CardHeading>Baseline Characteristics</CardHeading>
                  <Text>{selectedCohort.baselineCharacteristics || 'N/A'}</Text>
                  <br />

                  <TextProfileContainer className="text-profile-container">
                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Collaborators</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.collaborator && selectedCohort.collaborator.length > 0
                          ? selectedCohort.collaborator.join(', ')
                          : 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Drugs</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.drugs && selectedCohort.drugs.length > 0
                          ? selectedCohort.drugs.join(', ')
                          : 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Efficacy</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.efficacyData || 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Safety</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.safetyData || 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Trial Time Point</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.trialTimePoint || 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Trial Arm</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.trialArm || 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Number of Sites</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.numberOfSites || 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Phase</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.phase
                          ? isNaN(Number(selectedCohort.phase))
                            ? selectedCohort.phase
                            : `Phase ${selectedCohort.phase}`
                          : 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>

                    <TextProfileTextContainer className="text-profile-text-container">
                      <TextProfileLabel className="text-profile-label">Treatment Line</TextProfileLabel>
                      <TextProfileValue className="text-profile-value">
                        {selectedCohort.treatmentLine || 'N/A'}
                      </TextProfileValue>
                    </TextProfileTextContainer>
                  </TextProfileContainer>
                </CardMainBodyTextContainer>

                <CardSubBodyTextContainer>
                  <CardSubtitle>Inclusion Criteria</CardSubtitle>
                  <CriteriaButton
                    onClick={() => setShowInclusionCriteria(!showInclusionCriteria)}
                    intent="success"
                    rightIcon={showInclusionCriteria ? 'caret-up' : 'caret-down'}
                  >
                    {showInclusionCriteria ? 'Hide Inclusion Criteria' : 'View Inclusion Criteria'}
                  </CriteriaButton>
                  <Collapse isOpen={showInclusionCriteria}>{selectedCohort.inclusionCriteria || 'N/A'}</Collapse>

                  <CardSubtitle>Exclusion Criteria</CardSubtitle>
                  <CriteriaButton
                    onClick={() => setShowExclusionCriteria(!showExclusionCriteria)}
                    intent="success"
                    rightIcon={showExclusionCriteria ? 'caret-up' : 'caret-down'}
                  >
                    {showExclusionCriteria ? 'Hide Exclusion Criteria' : 'View Exclusion Criteria'}
                  </CriteriaButton>
                  <Collapse isOpen={showExclusionCriteria}>{selectedCohort.exclusionCriteria || 'N/A'}</Collapse>
                  <br />
                  <CardHeading>Countries</CardHeading>
                  <Text>
                    {selectedCohort.locationCountries && selectedCohort.locationCountries.length > 0
                      ? selectedCohort.locationCountries.join(', ')
                      : 'N/A'}
                  </Text>
                  <br />
                  <CardHeading>Terms</CardHeading>
                  {selectedCohort.terms && selectedCohort.terms.length > 0 ? (
                    <TermsContainer>
                      {selectedCohort.terms.map((term, index) => (
                        <TermTag key={`term-${term}-${index}`} minimal>
                          {term}
                        </TermTag>
                      ))}
                    </TermsContainer>
                  ) : (
                    'N/A'
                  )}
                </CardSubBodyTextContainer>
              </CohortCardBody>
            </CohortCard>
          </TrialCohortContainer>
        )}

        <TrialDetailsContainer>
          <TrialDetailsCard>
            <CardTitle className="card-title">Trial Details & Comparisons</CardTitle>
            <CardBody className="card-body">
              <TrialDetailsSuggest
                ref={suggestRef}
                items={selectedTrial ? selectedTrialCohorts : searchResults}
                itemRenderer={(item, { handleClick, modifiers, index }) => (
                  <MenuItem
                    key={
                      selectedTrial
                        ? `cohort-${item.cohort}-arm-${item.trialArm}-${index}`
                        : `trial-${item.trialName}-${index}`
                    }
                    text={
                      selectedTrial
                        ? `${item.cohort || 'N/A'} - ${item.trialArm || 'N/A'} - ${item.trialTimePoint || 'N/A'}`
                        : `${item.trialName} - (${item.nctId})`
                    }
                    onClick={handleClick}
                    active={modifiers.active}
                  />
                )}
                inputValueRenderer={(item) =>
                  cohortSelected
                    ? '' // Return empty string if cohort has been selected
                    : selectedTrial
                      ? item && item.cohort && item.trialArm
                        ? `${item.cohort} - ${item.trialArm}`
                        : `Select a cohort for ${selectedTrial.trialName}`
                      : item.trialName
                }
                onItemSelect={selectedTrial ? handleAtomicTrialSelect : handleTrialSelect}
                inputProps={{
                  placeholder: cohortSelected
                    ? 'Search for a trial...' // Reset to original placeholder
                    : selectedTrial
                      ? `Select a cohort for ${selectedTrial.trialName}`
                      : 'Search for a trial...',
                  value: query,
                }}
                noResults={<MenuItem disabled={true} text="No results." />}
                onQueryChange={(newQuery) => {
                  setQuery(newQuery);
                  setCohortSelected(false); // Reset cohortSelected when user starts typing
                  if (!selectedTrial) {
                    debouncedSearch(newQuery);
                  }
                }}
                resetOnSelect={true}
                popoverProps={{ minimal: true, matchTargetWidth: true }}
              />
              <ButtonContainer className="button-container">
                {additionalColumns.map((column) => (
                  <Button
                    key={`column-${column}`}
                    onClick={() => toggleColumnVisibility(column)}
                    intent={visibleColumns[column] ? 'primary' : 'none'}
                    size="small"
                  >
                    {column}
                  </Button>
                ))}
              </ButtonContainer>
              <TableWrapper className="table-wrapper">
                <StyledTable striped bordered>
                  <thead>
                    <tr>
                      <th>Trial Name</th>
                      {allColumns.slice(1).map((column) => visibleColumns[column] && <th key={column}>{column}</th>)}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(filteredResults || []).map((trial, index) => (
                      <tr key={`table-row-${trial.trialName}-${index}`}>
                        <td>{trial.trialName || 'N/A'}</td>
                        {allColumns.slice(1).map(
                          (column) =>
                            visibleColumns[column] && (
                              <td key={column}>
                                {(() => {
                                  switch (column) {
                                    case 'Trial Arm':
                                      return trial.trialArm || 'N/A';
                                    case 'NCT Number':
                                      return trial.nctId || 'N/A';
                                    case 'Trial Time Point':
                                      return trial.trialTimePoint || 'N/A';
                                    case 'Cohort':
                                      return trial.cohort || 'N/A';
                                    case 'Cohort Description':
                                      return trial.cohortDescription || 'N/A';
                                    case 'Start Date':
                                      return formatTimestamp(trial.actualStartDate);
                                    case 'End Date':
                                      return formatTimestamp(trial.actualReadoutDate);
                                    case 'Highest Phase':
                                      return `${trial.phase || 'N/A'}${
                                        trial.actualReadoutDate
                                          ? ` (Readout on ${formatTimestamp(trial.actualReadoutDate)})`
                                          : ''
                                      }`;
                                    case 'Drugs':
                                      return trial.drugs ? trial.drugs.join(', ') : 'N/A';
                                    case 'Status':
                                      return trial.trialStatus || 'N/A';
                                    case 'Enrollment':
                                      return trial.enrollmentActual
                                        ? `${trial.enrollmentActual} (Actual)`
                                        : trial.enrollmentEstimated
                                          ? `${trial.enrollmentEstimated} (Est.)`
                                          : 'N/A';
                                    case 'Indication':
                                      return trial.indications || 'N/A';
                                    case 'Primary Outcome':
                                      return renderOutcomeTags(trial.primaryEndpoints);
                                    case 'Treatment Line':
                                      return trial.treatmentLine || 'N/A';
                                    case 'Trial Efficacy':
                                      return trial.efficacyData || 'N/A';
                                    case 'Trial Safety':
                                      return trial.safetyData || 'N/A';
                                    case 'Sponsor':
                                      return (
                                        <SponsorLogos
                                          sponsors={formatSponsorNames(trial?.sponsorSimple || [])}
                                          height={25}
                                        />
                                      );
                                    case 'Collaborators':
                                      return (
                                        <SponsorLogos
                                          sponsors={formatSponsorNames(trial?.collaboratorSimple || [])}
                                          height={25}
                                        />
                                      );
                                    case 'Dose':
                                      return trial.cohortDose || 'N/A';
                                    case 'Baseline Traits':
                                      return trial.baselineCharacteristics || 'N/A';
                                    case 'Combination Therapy?':
                                      return trial.isCombinationTherapy ? (
                                        trial.isCombinationTherapy === 'Yes' ? (
                                          <Tag minimal intent="success">
                                            Yes
                                          </Tag>
                                        ) : (
                                          <Tag minimal intent="danger">
                                            No
                                          </Tag>
                                        )
                                      ) : (
                                        'N/A'
                                      );
                                    case 'Healthy Volunteers?':
                                      return trial.isHealthyVolunteerAccepted ? (
                                        trial.isHealthyVolunteerAccepted === 'Yes' ? (
                                          <Tag minimal intent="success">
                                            Yes
                                          </Tag>
                                        ) : (
                                          <Tag minimal intent="danger">
                                            No
                                          </Tag>
                                        )
                                      ) : (
                                        'N/A'
                                      );
                                    case 'Met Primary Endpoint?':
                                      return trial.metPrimaryEndpoint ? (
                                        trial.metPrimaryEndpoint === 'Yes' ? (
                                          <Tag minimal intent="success">
                                            Yes
                                          </Tag>
                                        ) : (
                                          <Tag minimal intent="danger">
                                            No
                                          </Tag>
                                        )
                                      ) : (
                                        'N/A'
                                      );
                                    case 'Similar Trials':
                                      return renderTrialLinks(trial?.similarTrials);
                                    case 'Prior Trials':
                                      return renderTrialLinks(trial?.priorTrials);
                                    case 'External Links':
                                      if (!trial.externalLinks || trial.externalLinks === 'N/A') return 'N/A';
                                      return (
                                        <>
                                          {trial.externalLinks.split(/[,;]/).map((url, index) => {
                                            const trimmedUrl = url.trim();
                                            if (!trimmedUrl) return null; // Skip empty strings
                                            return (
                                              <React.Fragment key={index}>
                                                {index > 0 && ' '}
                                                <a href={trimmedUrl} target="_blank" rel="noopener noreferrer">
                                                  {trimmedUrl.replace(/^@/, '')}
                                                </a>
                                              </React.Fragment>
                                            );
                                          })}
                                        </>
                                      );
                                    default:
                                      return trial[column] || 'N/A';
                                  }
                                })()}
                              </td>
                            ),
                        )}
                        <td>
                          {!atomicTrialsData.includes(trial) && (
                            <Button icon="cross" minimal onClick={() => handleRemoveAdditionalTrial(trial)} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>
            </CardBody>
          </TrialDetailsCard>
        </TrialDetailsContainer>
      </TrialBody>
    </TrialContainer>
  );
}
export default TrialsView;
