import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Colors, ButtonGroup, Spinner } from '@blueprintjs/core';
import styled from 'styled-components';
import html2pdf from 'html2pdf.js';
import { showToast } from '../Util/Toaster.js';

import { UserContext } from '../Contexts/UserContext';
import NetworkSettings from '../Hooks/NetworkSettings';
import StreamingMarkdown from '../Components/StreamingMarkdown';
import ResultTable from '../Components/ResultTable';

const API_URL = NetworkSettings.SERVER_URL;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const DeliverablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
`;

const DeliverablesList = styled.div`
  background-color: ${Colors.LIGHT_GRAY2};
  padding: 18px 24px;
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;

  border-radius: 4px;

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const DeliverableItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: ${Colors.WHITE};

  min-height: 150px;
  height: auto;
  padding: 12px 16px;

  // Add these properties to prevent shrinking
  flex: 0 0 auto;
  overflow: visible;
`;

const DeliverableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const DeliverableText = styled.div`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DeliverableName = styled.div`
  font-weight: 600;
  color: ${Colors.GREEN3};
`;

const DeliverableDate = styled.div`
  color: #888;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.8;
`;

const DeliverableButton = styled(Button)`
  width: 100%;
`;

const DeliverableDetails = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeliverableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeliverableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  background-color: ${Colors.WHITE};

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
`;

const DeliverableDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const DeliverableDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const DeliverableDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const DeliverableDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`;

const DeliverableDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  border-radius: 4px;
  padding: 12px;
  height: auto; // Allow height to grow with content
  overflow: visible; // Show overflow content
  flex: 0 0 auto; // Don't flex or shrink, just use natural height
`;

const EditableTextArea = styled.textarea`
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
  border-radius: 4px;
  padding: 12px;
  height: 100%;
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  background-color: ${Colors.LIGHT_GRAY5};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const EditContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
`;

const EditColumn = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

const DeliverableDetailsButtonGroup = styled(ButtonGroup)`
  width: 100%;
  gap: 12px;
`;

const TableContainer = styled.div`
  flex: 0 0 auto; // Don't grow or shrink
  width: 100%;
  padding: 12px 0;
`;

// ---------------------------------------------------------------------------------------------------------------------

// Helper function to parse deliverable contents
export const parseDeliverableContents = (deliverable) => {
  if (!deliverable?.data?.[0]?.contents) {
    console.debug('No contents found in deliverable:', deliverable);
    return {};
  }

  try {
    const contents = deliverable.data[0].contents;
    console.debug('Raw contents:', contents);

    // Handle case where contents is already an object
    if (typeof contents === 'object' && contents !== null) {
      console.debug('Contents is already an object:', contents);
      return contents;
    }

    // First parse
    let parsed = contents;
    try {
      parsed = JSON.parse(contents);
      console.debug('First parse result:', parsed);
    } catch (e) {
      console.warn('First parse failed:', e);
      return { text_markdown: contents }; // Fallback: treat as raw markdown
    }

    // Second parse if still a string
    if (typeof parsed === 'string') {
      try {
        parsed = JSON.parse(parsed);
        console.debug('Second parse result:', parsed);
      } catch (e) {
        console.warn('Second parse failed:', e);
        return { text_markdown: parsed }; // Fallback: treat as raw markdown
      }
    }

    // Validate expected structure
    if (!parsed.text_markdown && !parsed.table_string) {
      console.warn('Parsed result missing expected fields:', parsed);
      return { text_markdown: JSON.stringify(parsed, null, 2) }; // Fallback: show raw data
    }

    return parsed;
  } catch (e) {
    console.error('Error in parseDeliverableContents:', e);
    return { text_markdown: 'Error parsing deliverable contents' };
  }
};

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAIRepositoryView = () => {
  const params = useParams();
  const { deliverableID } = params;
  const { organizationId } = useContext(UserContext);

  const [deliverables, setDeliverables] = useState([]);
  const [deliverableContent, setDeliverableContent] = useState({});

  const [selectedDeliverableId, setSelectedDeliverableId] = useState(deliverableID);
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [copiedMessageId, setCopiedMessageId] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [longAnswer, setLongAnswer] = useState('');

  const [parsedContents, setParsedContents] = useState({});

  const originalLongAnswer = parsedContents.text_markdown;
  const longAnswerChanged = originalLongAnswer !== longAnswer;

  const targetRef = useRef(null);

  const navigate = useNavigate();

  // Get deliverables for user for initial load
  useEffect(() => {
    const fetchDeliverables = async () => {
      try {
        const response = await fetch(`${API_URL}/get-user-deliverables/`, {
          credentials: 'include',
        });

        const data = await response.json();
        setDeliverables(data.data);
      } catch (error) {
        console.error('Error fetching deliverables:', error);
      }
    };

    fetchDeliverables();
  }, []);

  // Fetch content for each deliverable for initial load
  useEffect(() => {
    const fetchDeliverableContent = async () => {
      setLoading(true);
      setError(null);

      try {
        const deliverablePromises = deliverables.map(async (deliverable) => {
          const response = await fetch(`${API_URL}/get-deliverable/${deliverable.deliverable_id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });

          if (!response.ok) {
            throw new Error(`Network response was not ok for deliverable ${deliverable.deliverable_id}`);
          }

          const data = await response.json();
          return { [deliverable.deliverable_id]: data };
        });

        const results = await Promise.all(deliverablePromises);
        const combinedDeliverables = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});

        setDeliverableContent(combinedDeliverables);
      } catch (error) {
        console.error('Error fetching deliverables:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDeliverableContent();
  }, [deliverables]);

  // Set the ID immediately from the URL
  useEffect(() => {
    if (deliverableID) {
      setSelectedDeliverableId(deliverableID);
    }
  }, [deliverableID]);

  // Update the content once it's available
  useEffect(() => {
    if (selectedDeliverableId && deliverableContent[selectedDeliverableId]) {
      setSelectedDeliverable(deliverableContent[selectedDeliverableId]);
      const contents = parseDeliverableContents(deliverableContent[selectedDeliverableId]);
      setParsedContents(contents);
      setLongAnswer(contents.text_markdown || '');
    }
  }, [selectedDeliverableId, deliverableContent]);

  // Update selected deliverable and navigate to it
  const handleDeliverableSelect = (id) => {
    setSelectedDeliverableId(id);
    setSelectedDeliverable(deliverableContent[id]);
    setLongAnswer(deliverableContent[id]?.data[0].contents?.text_markdown);

    navigate(`/autonomous-ai/repository/${id}`);
  };

  // Handle pressing the edit button
  const handleEdit = async () => {
    // If this is a save, send API request to update long answer
    if (isEditing && longAnswerChanged) {
      const requestBody = {
        deliverable_id: selectedDeliverableId,
        name: selectedDeliverable.data[0].name,
        contents: JSON.stringify({
          text_markdown: longAnswer,
          table_string: parsedContents.table_string,
        }),
      };
      try {
        const response = await fetch(`${API_URL}/save-deliverable/`, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok for save deliverable`);
        }
        const data = await response.json();
      } catch (error) {
        console.error('Error saving deliverable:', error);
        showToast({
          message: `Failed to save deliverable: ${error.message}`,
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      } finally {
        showToast({
          message: `Deliverable saved successfully`,
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      }
    }

    setIsEditing(!isEditing);
  };

  // Handle PDF download
  const handleDownload = async () => {
    if (targetRef.current) {
      const element = targetRef.current;
      const originalStyle = element.style.cssText;

      // Store original graph container styles
      const graphContainer = element.querySelector('.deliverable-details-graph');
      const originalGraphStyle = graphContainer ? graphContainer.style.cssText : '';

      // Temporary styles for PDF generation
      element.style.height = 'auto';
      element.style.maxHeight = 'none';
      element.style.overflow = 'visible';
      element.style.padding = '12px';

      // Adjust spacing
      const headerElements = element.querySelectorAll('.deliverable-details-header, .deliverable-details-body');
      headerElements.forEach((el) => {
        el.style.margin = '0';
        el.style.padding = '0';
        el.style.gap = '8px';
      });

      // Ensure graph captures fully
      if (graphContainer) {
        graphContainer.style.width = '90%';
        graphContainer.style.margin = '12px auto';
        graphContainer.style.maxWidth = '800px';

        const canvas = graphContainer.querySelector('canvas');
        if (canvas) {
          canvas.style.width = '100%';
          canvas.style.maxWidth = '100%';
        }
      }

      const opt = {
        margin: 0,
        filename: `${selectedDeliverable.data[0].name || 'deliverable'}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          windowWidth: 794, // 210mm converted to pixels at 96 DPI
          width: 794,
          onclone: function (clonedDoc) {
            const clonedGraph = clonedDoc.querySelector('.deliverable-details-graph');
            if (clonedGraph) {
              clonedGraph.style.width = '90%';
              clonedGraph.style.margin = '12px auto';
              clonedGraph.style.maxWidth = '800px';
            }
          },
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
          compress: true,
          precision: 16,
        },
        pagebreak: {
          mode: 'avoid-all',
        },
      };

      try {
        await html2pdf().set(opt).from(element).save();

        showToast({
          message: 'PDF downloaded successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      } catch (error) {
        // Show error toast
        showToast({
          message: 'Failed to generate PDF',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        console.error('PDF generation error:', error);
      } finally {
        // Only restore styles, not content
        element.style.cssText = originalStyle;
        if (graphContainer) {
          graphContainer.style.cssText = originalGraphStyle;
        }

        // Restore other element styles
        headerElements.forEach((el) => {
          el.style.margin = '';
          el.style.padding = '';
          el.style.gap = '';
        });
      }
    }
  };

  // Copy share link to clipboard
  const handleCopyShareLink = (deliverableID) => {
    navigator.clipboard
      .writeText(`${window.location.origin}/autonomous-ai/deliverables/${deliverableID}`)
      .then(() => {
        showToast({
          message: 'Share link copied to clipboard',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Deliverables Repository</PageTitle>
        <PageSubtitle className="page-subtitle">
          View deliverable results from tasks here. You can also give feedback and download the deliverable.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        {loading ? (
          <Spinner />
        ) : !deliverables.length ? (
          <div>No deliverables found</div>
        ) : (
          <DeliverablesContainer className="deliverables-container">
            <DeliverablesList className="deliverables-list">
              {deliverables.map((deliverable) => (
                <DeliverableItem key={deliverable.deliverable_id} className="deliverable-item">
                  <DeliverableTextContainer>
                    <DeliverableText>
                      <DeliverableName>{deliverable.name}</DeliverableName>
                    </DeliverableText>
                    <DeliverableText>
                      <DeliverableDate>
                        {new Date(deliverable.updated_at).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </DeliverableDate>
                    </DeliverableText>
                  </DeliverableTextContainer>

                  <DeliverableButton
                    fill
                    small
                    intent="primary"
                    icon="eye-open"
                    onClick={() => handleDeliverableSelect(deliverable.deliverable_id)}
                  >
                    View
                  </DeliverableButton>
                </DeliverableItem>
              ))}
            </DeliverablesList>

            <DeliverableDetails className="deliverable-details">
              {selectedDeliverable?.data[0] && (
                <DeliverableDetailsContainer className="deliverable-details-container">
                  <DeliverableDetailsWrapper className="deliverable-details-wrapper" ref={targetRef}>
                    <DeliverableDetailsHeader className="deliverable-details-header">
                      <DeliverableDetailsTitle>{selectedDeliverable.data[0].name}</DeliverableDetailsTitle>
                      <DeliverableDetailsDate>
                        {new Date(selectedDeliverable.data[0].updated_at).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </DeliverableDetailsDate>
                    </DeliverableDetailsHeader>
                    <DeliverableDetailsBody className="deliverable-details-body">
                      {parsedContents.text_markdown && (
                        <DeliverableDetailsLongAnswer className="deliverable-details-long-answer">
                          {isEditing ? (
                            <EditContainer>
                              <EditColumn>
                                <EditableTextArea value={longAnswer} onChange={(e) => setLongAnswer(e.target.value)} />
                              </EditColumn>
                              <EditColumn>
                                <StreamingMarkdown content={longAnswer} />
                              </EditColumn>
                            </EditContainer>
                          ) : (
                            <StreamingMarkdown content={longAnswer} />
                          )}
                        </DeliverableDetailsLongAnswer>
                      )}

                      {parsedContents.table_string && (
                        <TableContainer>
                          <ResultTable
                            tableString={parsedContents.table_string}
                            initialVisibleColumns={[
                              'drug_name',
                              'sponsor',
                              'nct_id',
                              'trial_name',
                              'trial_status',
                              'efficacy_data',
                              'safety_data',
                            ]}
                            limitTableWidth={true}
                          />
                        </TableContainer>
                      )}
                    </DeliverableDetailsBody>
                  </DeliverableDetailsWrapper>

                  <DeliverableDetailsButtonGroup className="deliverable-details-button-group">
                    <Button
                      intent="success"
                      icon="share"
                      text="Share"
                      onClick={() => handleCopyShareLink(selectedDeliverableId)}
                    />
                    <Button
                      intent="success"
                      icon="download"
                      text="Download"
                      onClick={handleDownload}
                      disabled={!selectedDeliverableId || longAnswerChanged}
                    />
                    <Button
                      intent="success"
                      icon={isEditing ? (longAnswerChanged ? 'floppy-disk' : 'tick') : 'edit'}
                      text={isEditing ? (longAnswerChanged ? 'Save' : 'Saved') : 'Edit'}
                      onClick={() => handleEdit()}
                      disabled={!selectedDeliverableId || (!longAnswerChanged && isEditing)}
                    />
                  </DeliverableDetailsButtonGroup>
                </DeliverableDetailsContainer>
              )}
            </DeliverableDetails>
          </DeliverablesContainer>
        )}
      </PageBody>
    </ViewContainer>
  );
};

export default AutonomousAIRepositoryView;
