import React, { useState, useEffect, useCallback } from 'react';

import {
  Button,
  Colors,
  InputGroup,
  Spinner,
  Divider,
  FormGroup,
  TextArea,
  Dialog,
  DialogBody,
  DialogFooter,
} from '@blueprintjs/core';

import styled from 'styled-components';

import NetworkSettings from '../Hooks/NetworkSettings.js';
import { showToast } from '../Util/Toaster.js';

import StreamingMarkdown from '../Components/StreamingMarkdown.js';
import ResultTable from '../Components/ResultTable.js';

const API_URL = NetworkSettings.SERVER_URL;

const ViewContainer = styled.div`
  padding: 12px 24px 100px;
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
`;

const TasksList = styled.div`
  background-color: ${Colors.LIGHT_GRAY2};
  padding: 18px 24px;
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;

  border-radius: 4px;

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TaskItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: ${Colors.WHITE};

  min-height: 150px;
  height: auto;
  padding: 12px 16px;

  flex: 0 0 auto;
  overflow: visible;
`;

const TaskTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const TaskText = styled.div`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TaskName = styled.div`
  font-weight: 600;
  color: ${Colors.GREEN3};
`;

const TaskDate = styled.div`
  color: #888;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.8;
`;

const TaskButton = styled(Button)`
  width: 100%;
`;

const TaskDetails = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TaskDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TaskDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  background-color: ${Colors.WHITE};

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
`;

const TaskDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const TaskDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const TaskDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const TaskDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`;

const TaskDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  border-radius: 4px;
  padding: 12px;
  height: auto; // Allow height to grow with content
  overflow: visible; // Show overflow content
  flex: 0 0 auto; // Don't flex or shrink, just use natural height
`;

const TableContainer = styled.div`
  flex: 0 0 auto; // Don't grow or shrink
  width: 100%;
  padding: 12px 0;
`;

const TaskDetailsInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TaskFeedback = styled(TextArea)`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
`;

const SaveDeliverableDialogFooter = styled(DialogFooter)`
  & .bp5-dialog-footer-main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAIWorkspace = () => {
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [selectedThreadDetails, setSelectedThreadDetails] = useState(null);

  const [feedback, setFeedback] = useState('');
  const [deliverableName, setDeliverableName] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const toggleOverlay = useCallback(() => setIsOpen((open) => !open), [setIsOpen]);

  // Get threads from user
  useEffect(() => {
    const fetchThreads = async (isInitialLoad = false) => {
      if (isInitialLoad) {
        setLoading(true);
      }
      setError(null);

      try {
        const response = await fetch(`${API_URL}/get-threads-by-user/`, {
          credentials: 'include',
        });

        const data = await response.json();
        setThreads((prevThreads) => {
          // If there are no previous threads (initial load), just return new data
          if (!prevThreads.length) return data.data;

          // Otherwise, carefully merge the new data
          return prevThreads.map((prevThread) => {
            const updatedThread = data.data.find((t) => t.thread_id === prevThread.thread_id);
            return updatedThread || prevThread;
          });
        });
      } catch (error) {
        console.error('Error fetching threads:', error);
        setError(error.message);
        showToast({
          message: `Failed to fetch tasks: ${error.message}`,
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      } finally {
        if (isInitialLoad) {
          setLoading(false);
        }
      }
    };

    // Initial fetch with loading state
    fetchThreads(true);

    // Set up polling interval without loading state
    const interval = setInterval(() => fetchThreads(false), 60000);

    return () => clearInterval(interval);
  }, []);

  // Get thread details
  useEffect(() => {
    if (!selectedThreadId) return;
    const fetchThreadDetails = async () => {
      const response = await fetch(`${API_URL}/agent-checkpoints/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id: selectedThreadId }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for thread ${selectedThreadId}`);
      }

      const data = await response.json();
      setSelectedThreadDetails(data.data);
      setDeliverableName(data.data.messages[0].content);
    };

    fetchThreadDetails();
  }, [selectedThreadId]);

  const handleTaskSelect = (threadId) => {
    setSelectedThreadId(threadId);
  };

  const handleSendFeedback = async () => {
    try {
      const response = await fetch(`${API_URL}/agent-feedback/`, {
        method: 'POST',
        body: JSON.stringify({ thread_id: selectedThreadId, feedback: feedback }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for feedback`);
      }

      const data = await response.json();
      console.log('data', data);
    } catch (error) {
      console.error('Error sending feedback:', error);
      showToast({
        message: `Failed to send feedback: ${error.message}`,
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  const handleSaveToRepository = async () => {
    const requestBody = {
      thread_id: selectedThreadId,
      name: deliverableName,
      contents: {
        text_markdown: selectedThreadDetails.messages[3].content,
        table_string: selectedThreadDetails.messages[2].content,
      },
    };
    try {
      const response = await fetch(`${API_URL}/create-deliverable/`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for save deliverable`);
      }

      const data = await response.json();
    } catch (error) {
      console.error('Error saving deliverable:', error);
      showToast({
        message: `Failed to save deliverable: ${error.message}`,
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      showToast({
        message: `Deliverable saved successfully.`,
        intent: 'success',
        icon: 'tick',
        title: 'Elementary!',
      });
      setDeliverableName('');
    }
  };

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Autonomous AI Workspace</PageTitle>
        <PageSubtitle className="page-subtitle">
          Autonomous AI Workspace is where you can manage autonomous AI agents. Add feedback or more context to tasks to
          help your AI agents perform better. If the task is complete, you can save the output as a deliverable for
          future reference.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        {loading ? (
          <Spinner />
        ) : !threads.length ? (
          <div>No tasks found</div>
        ) : (
          <TasksContainer className="tasks-container">
            <TasksList className="tasks-list">
              {threads.map((thread) => (
                <TaskItem key={thread.thread_id} className="task-item">
                  <TaskTextContainer>
                    <TaskText>
                      <TaskName>{thread.query}</TaskName>
                    </TaskText>
                    <TaskText>
                      <TaskDate>
                        {new Date(thread.timestamp).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </TaskDate>
                    </TaskText>
                  </TaskTextContainer>

                  <TaskButton
                    fill
                    small
                    intent="primary"
                    icon={thread.step === 3 ? 'eye-open' : 'eye-off'}
                    onClick={() => handleTaskSelect(thread.thread_id)}
                    disabled={thread.step !== 3}
                  >
                    {thread.step === 3 ? 'View' : 'Pending'}
                  </TaskButton>
                </TaskItem>
              ))}
            </TasksList>

            <TaskDetails className="task-details">
              {selectedThreadDetails && selectedThreadDetails.messages && (
                <TaskDetailsContainer className="task-details-container">
                  <TaskDetailsWrapper className="task-details-wrapper">
                    <TaskDetailsHeader className="task-details-header">
                      <TaskDetailsTitle className="task-details-title">
                        {selectedThreadDetails.messages[0].content}
                      </TaskDetailsTitle>
                      <TaskDetailsDate>
                        {new Date(selectedThreadDetails.timestamp).toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </TaskDetailsDate>
                    </TaskDetailsHeader>

                    <TaskDetailsBody className="task-details-body">
                      {selectedThreadDetails.messages[3] && (
                        <TaskDetailsLongAnswer className="task-details-long-answer">
                          <StreamingMarkdown content={selectedThreadDetails.messages[3].content} />
                        </TaskDetailsLongAnswer>
                      )}

                      {selectedThreadDetails.messages[2] && (
                        <TableContainer>
                          <ResultTable
                            tableString={selectedThreadDetails.messages[2].content}
                            initialVisibleColumns={[
                              'drug_name',
                              'sponsor',
                              'nct_id',
                              'trial_name',
                              'trial_status',
                              'efficacy_data',
                              'safety_data',
                            ]}
                            limitTableWidth={true}
                          />
                        </TableContainer>
                      )}
                    </TaskDetailsBody>

                    <Divider />

                    <TaskDetailsInputContainer className="task-details-input-container">
                      <FormGroup label="Feedback">
                        <TaskFeedback
                          placeholder="Add feedback or more context to help your AI agents perform better."
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                        />
                      </FormGroup>

                      <ButtonsContainer className="buttons-container">
                        <Button
                          intent="primary"
                          icon="send-message"
                          text="Send Feedback"
                          onClick={handleSendFeedback}
                        />

                        <Button intent="primary" icon="floppy-disk" text="Save to Repository" onClick={toggleOverlay} />
                        <Dialog isOpen={isOpen} onClose={toggleOverlay}>
                          <DialogBody>
                            <h3>Save Deliverable</h3>
                            <FormGroup label="Deliverable Name">
                              <InputGroup
                                placeholder="Enter a name for your deliverable"
                                value={deliverableName}
                                onChange={(e) => setDeliverableName(e.target.value)}
                              />
                            </FormGroup>
                          </DialogBody>
                          <SaveDeliverableDialogFooter className="save-deliverable-dialog-footer">
                            <Button text="Cancel" onClick={toggleOverlay} />
                            <Button
                              intent="primary"
                              icon="floppy-disk"
                              text="Save"
                              onClick={() => {
                                handleSaveToRepository();
                                toggleOverlay();
                              }}
                            />
                          </SaveDeliverableDialogFooter>
                        </Dialog>
                      </ButtonsContainer>
                    </TaskDetailsInputContainer>
                  </TaskDetailsWrapper>
                </TaskDetailsContainer>
              )}
            </TaskDetails>
          </TasksContainer>
        )}
      </PageBody>
    </ViewContainer>
  );
};

export default AutonomousAIWorkspace;
