import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const IntelligenceSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate('/intelligence', { state: { searchQuery } });
    }
  };

  return (
    <Container
      fluid
      className="intelligence-search d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: '100vh' }}
    >
      <h1 className="mb-4">What do you want to diligence?</h1>
      <Form onSubmit={handleSubmit} className="w-100" style={{ maxWidth: '600px' }}>
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Specify a project name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form.Group>
        <Button style={{ backgroundColor: '#4A6741' }} type="submit" className="w-100">
          Create
        </Button>
      </Form>
    </Container>
  );
};

export default IntelligenceSearch;
