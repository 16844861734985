import React, { createContext, useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// APIs
import { fetchScenarioById, fetchScenariosByTrialName } from '../Hooks/ScenarioAPI.js';
import { fetchTrialByName, fetchCompletedTrialByName } from '../Hooks/TrialAPI.js';

// Utils
import { snakeToCamel } from '../Util/CaseConvert.js';
import { updateSessionStorageValue, getSessionStorageValue } from '../Util/SessionStorage.js';

// Create the context
const GlobalDataContext = createContext();

const initialDataState = {
  currentTrial: null,
  currentEndpoint: null,
  currentScenario: null,
  savedScenarios: [],
  unsavedScenarios: [],
  scenariosToSimulate: [],
  removedScenarios: [],
};

// Infer the endpoint from the scenario
const inferEndpoint = (scenario) => {
  if (scenario.endpoint) return scenario.endpoint;
  if (scenario.outputs) {
    if (scenario.outputs.os) return 'os';
    if (scenario.outputs.pfs) return 'pfs';
  }
  return null;
};

// Reducer for the scenario state
const scenarioReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SAVED_SCENARIOS':
      return {
        ...state,
        savedScenarios: action.payload.map((scenario) => ({
          ...scenario,
          endpoint: inferEndpoint(scenario),
        })),
      };
    case 'ADD_UNSAVED_SCENARIO':
      return {
        ...state,
        unsavedScenarios: [
          ...state.unsavedScenarios,
          {
            ...action.payload,
            endpoint: inferEndpoint(action.payload),
          },
        ],
      };
    case 'REMOVE_UNSAVED_SCENARIO':
      return { ...state, unsavedScenarios: state.unsavedScenarios.filter((s) => s.id !== action.payload) };
    case 'SET_SCENARIOS_TO_SIMULATE':
      return { ...state, scenariosToSimulate: action.payload };
    case 'ADD_TO_SIMULATE':
      return {
        ...state,
        scenariosToSimulate: [
          ...state.scenariosToSimulate,
          {
            ...action.payload,
            endpoint: inferEndpoint(action.payload),
          },
        ],
        currentEndpoint:
          state.scenariosToSimulate.length === 0
            ? inferEndpoint(action.payload) || state.currentEndpoint
            : state.currentEndpoint,
      };
    case 'REMOVE_FROM_SIMULATE':
      const updatedScenarios = state.scenariosToSimulate.filter(
        (s) =>
          s.id !== action.payload &&
          s.name !== action.payload &&
          s.scenarioId !== action.payload &&
          s.scenarioName !== action.payload,
      );
      return {
        ...state,
        scenariosToSimulate: updatedScenarios,
      };
    case 'SET_REMOVED_SCENARIOS':
      return { ...state, removedScenarios: action.payload };
    default:
      return state;
  }
};

// Utility function to get all sessionStorage values
const getAllSessionStorage = () => {
  const allStorage = {};
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    allStorage[key] = getSessionStorageValue(key);
  }
  return allStorage;
};

const GlobalDataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(scenarioReducer, initialDataState);
  const [currentTrial, setCurrentTrial] = useState(null);
  const [currentEndpoint, setCurrentEndpoint] = useState(null);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [currentScenarios, setCurrentScenarios] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [resourceType, setResourceType] = useState(null);
  const [identifier, setIdentifier] = useState(null);

  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/').filter(Boolean);

  // Update resourceType and identifier when URL changes
  useEffect(() => {
    if (parts.length === 2 && parts[1] !== 'undefined') {
      setResourceType(parts[0]);
      setIdentifier(parts[1]);
    }
  }, [parts]);

  const lastFetchedScenario = useRef(null);
  const lastFetchedTrial = useRef(null);

  // Load trial and scenarios
  const loadTrialAndScenarios = useCallback(async () => {
    if (!resourceType || !identifier || isLoading) return;

    // Prevent refetching if the trial is already loaded
    if (currentTrial && currentTrial.trialName === identifier) return;

    setIsLoading(true);
    try {
      let trialData, scenarioData, scenariosData;

      // Initialize for scenarioResults
      if (resourceType === 'scenarioResults') {
        if (lastFetchedScenario.current !== identifier) {
          // Check if the scenario is unsaved
          const unsavedScenario = state.unsavedScenarios.find((s) => s.id === identifier);
          const unsavedScenarioInCurrentScenarios = currentScenarios.find((s) => s.id === identifier);

          if (unsavedScenario || unsavedScenarioInCurrentScenarios) {
            scenarioData = unsavedScenario || unsavedScenarioInCurrentScenarios;
          } else {
            const scenarioResponse = await fetchScenarioById(identifier);
            scenarioData = snakeToCamel(scenarioResponse);
          }

          // If the fetched scenario doesn't have outputs, try to find a version with outputs
          if (!scenarioData.outputs) {
            const scenarioWithOutputs = currentScenarios.find((s) => s.id === identifier && s.outputs);
            if (scenarioWithOutputs) {
              scenarioData = { ...scenarioData, outputs: scenarioWithOutputs.outputs };
            }
          }

          setCurrentScenario({
            ...scenarioData,
            endpoint: inferEndpoint(scenarioData),
          });
          lastFetchedScenario.current = identifier;

          // Set the current endpoint based on the scenario data
          if (scenarioData.outputs) {
            setCurrentEndpoint(inferEndpoint(scenarioData));
          }

          if (lastFetchedTrial.current !== scenarioData.trialName) {
            const trialResponse = await fetchTrialByName(scenarioData.trialName);
            trialData = snakeToCamel(trialResponse);
            setCurrentTrial(trialData);
            lastFetchedTrial.current = scenarioData.trialName;

            if (!currentEndpoint && trialData.availableEndpoints?.length > 0) {
              setCurrentEndpoint(trialData.availableEndpoints[0]);
            }
          }
        }
        // Initialize for trial or scenarioBuilder
      } else if (resourceType === 'trial' || resourceType === 'scenarioBuilder') {
        if (!currentTrial || currentTrial.trialName !== identifier) {
          const trialResponse = await fetchTrialByName(identifier);
          trialData = snakeToCamel(trialResponse);
          setCurrentTrial(trialData);

          // Set initial endpoint if not already set
          if (!currentEndpoint && trialData.availableEndpoints?.length > 0) {
            setCurrentEndpoint(trialData.availableEndpoints[0]);
          }

          // Clear scenarios when switching to a new trial
          dispatch({ type: 'SET_SAVED_SCENARIOS', payload: [] });
          dispatch({ type: 'CLEAR_UNSAVED_SCENARIOS' });
          dispatch({ type: 'SET_SCENARIOS_TO_SIMULATE', payload: [] });
          setCurrentScenarios([]);
          setCurrentScenario(null);
        } else {
          trialData = currentTrial;
        }

        if (resourceType === 'scenarioBuilder') {
          // Fetch scenarios for the trial
          const scenariosResponse = await fetchScenariosByTrialName(trialData.trialName);
          scenariosData = snakeToCamel(scenariosResponse).map((scenario) => ({
            ...scenario,
            endpoint: inferEndpoint(scenario),
          }));

          // Include unsaved scenarios for the current trial
          const unsavedScenariosForTrial = state.unsavedScenarios.filter(
            (scenario) => scenario.trialName === trialData.trialName,
          );

          // Combine saved and unsaved scenarios
          scenariosData = [
            ...scenariosData,
            ...unsavedScenariosForTrial.map((scenario) => ({
              ...scenario,
              endpoint: inferEndpoint(scenario),
            })),
          ];

          dispatch({ type: 'SET_SAVED_SCENARIOS', payload: scenariosData });
          setCurrentScenarios(scenariosData);
        }
      }
    } catch (error) {
      console.error('GlobalDataContext Error:', error);
    } finally {
      setIsLoading(false);
    }
  }, [resourceType, identifier]);

  useEffect(() => {
    loadTrialAndScenarios();
  }, [loadTrialAndScenarios, resourceType, identifier]);

  const setSavedScenarios = useCallback((scenarios) => {
    const scenariosArray = Array.isArray(scenarios) ? scenarios : scenarios.scenarios || [];
    dispatch({ type: 'SET_SAVED_SCENARIOS', payload: scenariosArray });
    updateSessionStorageValue('savedScenarios', scenariosArray);
  }, []);

  const addUnsavedScenario = useCallback((scenario) => {
    dispatch({ type: 'ADD_UNSAVED_SCENARIO', payload: scenario });
    dispatch({ type: 'ADD_TO_SIMULATE', payload: scenario });
  }, []);

  const removeUnsavedScenario = useCallback((scenarioId) => {
    dispatch({ type: 'REMOVE_UNSAVED_SCENARIO', payload: scenarioId });
    dispatch({ type: 'REMOVE_FROM_SIMULATE', payload: scenarioId });
  }, []);

  const addToSimulate = useCallback(
    (scenario) => {
      const endpoint = inferEndpoint(scenario) || currentEndpoint;
      dispatch({ type: 'ADD_TO_SIMULATE', payload: { ...scenario, endpoint } });
    },
    [currentEndpoint],
  );

  const removeFromSimulate = useCallback((scenario) => {
    const scenarioId = scenario.id || scenario.scenarioId || scenario.name || scenario.scenarioName;
    dispatch({ type: 'REMOVE_FROM_SIMULATE', payload: scenarioId });
  }, []);

  const deleteSavedScenario = useCallback(
    async (scenario) => {
      dispatch({
        type: 'SET_SAVED_SCENARIOS',
        payload: state.savedScenarios.filter((s) => s.scenarioId !== scenario.scenarioId),
      });
      dispatch({ type: 'REMOVE_FROM_SIMULATE', payload: scenario.scenarioId });
    },
    [state.savedScenarios],
  );

  const updateUnsavedScenario = (scenario) => {
    dispatch({ type: 'UPDATE_UNSAVED_SCENARIO', payload: scenario });
  };

  const addRemovedScenario = (scenarioId) => {
    dispatch({ type: 'SET_REMOVED_SCENARIOS', payload: [...state.removedScenarios, scenarioId] });
  };

  const restoreRemovedScenario = (scenarioId) => {
    dispatch({ type: 'SET_REMOVED_SCENARIOS', payload: state.removedScenarios.filter((id) => id !== scenarioId) });
  };

  const contextValue = {
    currentTrial,
    setCurrentTrial,
    currentEndpoint,
    setCurrentEndpoint,
    currentScenario,
    setCurrentScenario,
    currentScenarios,
    setCurrentScenarios,
    savedScenarios: state.savedScenarios || [],
    setSavedScenarios,
    unsavedScenarios: state.unsavedScenarios || [],
    addUnsavedScenario,
    updateUnsavedScenario,
    removeUnsavedScenario,
    removedScenarios: state.removedScenarios || [],
    addRemovedScenario,
    restoreRemovedScenario,
    scenariosToSimulate: state.scenariosToSimulate || [],
    addToSimulate,
    removeFromSimulate,
    deleteSavedScenario,
    isLoading,
    resourceType,
    identifier,
    inferEndpoint,
  };

  // Load data from sessionStorage on initial render
  useEffect(() => {
    const storedTrial = getSessionStorageValue('currentTrial');
    const storedEndpoint = getSessionStorageValue('currentEndpoint');
    const storedScenarios = getSessionStorageValue('currentScenarios', []);
    const storedSavedScenarios = getSessionStorageValue('savedScenarios', []);

    if (storedTrial) setCurrentTrial(storedTrial);
    if (storedEndpoint) setCurrentEndpoint(storedEndpoint);
    if (storedScenarios.length > 0) setCurrentScenarios(storedScenarios);
    if (storedSavedScenarios.length > 0) dispatch({ type: 'SET_SAVED_SCENARIOS', payload: storedSavedScenarios });
  }, []);

  // Save data to sessionStorage whenever it changes
  useEffect(() => {
    updateSessionStorageValue('currentTrial', currentTrial);
    updateSessionStorageValue('currentEndpoint', currentEndpoint);
    updateSessionStorageValue('currentScenarios', currentScenarios);
    updateSessionStorageValue('savedScenarios', state.savedScenarios);
  }, [currentTrial, currentEndpoint, currentScenarios, state.savedScenarios]);

  // Clear currentScenario when resourceType changes
  useEffect(() => {
    if (resourceType !== 'scenarioResults') {
      setCurrentScenario(null);
    }
  }, [resourceType]);

  // Set current endpoint based on sessionStorage or trial data
  useEffect(() => {
    if (currentTrial && currentTrial.availableEndpoints && currentTrial.availableEndpoints.length > 0) {
      const storedEndpoint = getSessionStorageValue('currentEndpoint');
      if (storedEndpoint && currentTrial.availableEndpoints.includes(storedEndpoint)) {
        setCurrentEndpoint(storedEndpoint);
      } else {
        setCurrentEndpoint(currentTrial.availableEndpoints[0]);
      }
    }
  }, [currentTrial, setCurrentEndpoint]);

  return <GlobalDataContext.Provider value={contextValue}>{children}</GlobalDataContext.Provider>;
};

export { GlobalDataContext, GlobalDataContextProvider };
