import React, { useEffect, useState, useRef } from 'react';
import { Card, Form, Button, Collapse, Accordion, ListGroup, ProgressBar, Alert } from 'react-bootstrap';
import LoadProjectsAPI from '../Hooks/LoadProjectsAPI';
import axios from 'axios';

import NetworkSettings from '../Hooks/NetworkSettings';

const API_URL = NetworkSettings.SERVER_URL;

const DirectoryStructure = ({ data, level = 0 }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const renderItem = (item) => {
    if (item.type === 'directory') {
      return (
        <ListGroup.Item key={item.name} className="directory-item">
          <Button
            onClick={toggleOpen}
            aria-controls={`collapse-${item.name}`}
            aria-expanded={open}
            variant="light"
            className="directory-button w-100 text-start"
            style={{ paddingLeft: `${level * 20}px` }}
          >
            {open ? '▼' : '▶'} {item.name}
          </Button>
          <Collapse in={open}>
            <div id={`collapse-${item.name}`} className="directory-children">
              {item.children &&
                item.children.map((child) => <DirectoryStructure key={child.name} data={child} level={level + 1} />)}
            </div>
          </Collapse>
        </ListGroup.Item>
      );
    } else {
      return (
        <div key={item.name} className="file-item" style={{ paddingLeft: `${(level + 1) * 20}px` }}>
          └─ {item.name}
        </div>
      );
    }
  };

  return renderItem(data);
};

const Projects = ({ className }) => {
  const [userProjects, setUserProjects] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [userFiles, setUserFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);

  const { getUserProjects } = LoadProjectsAPI(setUserProjects);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await getUserProjects();
        await fetchUserFiles();
      } catch (e) {
        console.error(e);
        setError('Failed to load projects or user files');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [getUserProjects]);

  const fetchUserFiles = async () => {
    try {
      const response = await axios.get(`${API_URL}/user-files/`, { withCredentials: true });
      setUserFiles(response.data.files);
    } catch (error) {
      console.error('Failed to fetch user files:', error);
      setError('Failed to load user files');
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    setUploadingFiles(files.map((file) => ({ file, progress: 0 })));

    for (const file of files) {
      try {
        const formData = new FormData();
        formData.append('file', file);

        await axios.post(`${API_URL}/upload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadingFiles((prev) => prev.map((f) => (f.file === file ? { ...f, progress } : f)));
          },
        });

        setUploadingFiles((prev) => prev.filter((f) => f.file !== file));
        fetchUserFiles();
      } catch (error) {
        console.error('Upload failed:', error);
        setError(`Failed to upload ${file.name}: ${error.message}`);
      }
    }
  };

  const handleDeleteFile = async (fileName) => {
    try {
      await axios.delete(`${API_URL}/delete-file/${fileName}`, { withCredentials: true });
      fetchUserFiles();
    } catch (error) {
      console.error('Delete failed:', error);
      setError(`Failed to delete ${fileName}`);
    }
  };

  const handleViewFile = async (fileName) => {
    try {
      const response = await axios.get(`${API_URL}/get-signed-url/${fileName}`, { withCredentials: true });
      window.open(response.data.signed_url, '_blank');
    } catch (error) {
      console.error('Failed to get signed URL:', error);
      setError(`Failed to view ${fileName}`);
    }
  };

  const buttonStyle = {
    backgroundColor: '#4A6741',
    borderColor: '#4A6741',
    color: 'white',
  };

  return (
    <Card className={`projects ${className}`}>
      <Card.Body>
        <Form className="mb-4">
          <Form.Group>
            <Form.Control type="text" placeholder="Search across your projects" className="ai-search" />
          </Form.Group>
        </Form>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Card.Title className="mb-0">Projects</Card.Title>
          <Button style={buttonStyle} onClick={handleUploadClick}>
            Upload File
          </Button>
          <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} multiple />
        </div>

        {error && <Alert variant="danger">{error}</Alert>}

        {uploadingFiles.map(({ file, progress }) => (
          <div key={file.name} className="mb-2">
            <div>{file.name}</div>
            <ProgressBar now={progress} label={`${progress}%`} />
          </div>
        ))}

        <div className="mb-4">
          {isLoading ? (
            <div>Loading projects...</div>
          ) : userProjects && userProjects.length > 0 ? (
            <Accordion className="directory-structure">
              {userProjects.map((project, index) => (
                <DirectoryStructure key={project.name} data={project} />
              ))}
            </Accordion>
          ) : (
            <div>No projects found</div>
          )}
        </div>

        <div className="mt-4" style={{ borderTop: '1px solid #dee2e6', paddingTop: '1rem' }}>
          <h5>User Files</h5>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Your Uploaded Files</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {userFiles?.map((file) => (
                    <ListGroup.Item key={file.id} className="d-flex justify-content-between align-items-center">
                      <span
                        className="text-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleViewFile(file.s3_object_key)}
                      >
                        {file.original_filename}
                      </span>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteFile(file.s3_object_key)}>
                        Delete
                      </Button>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Projects;
