import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Card, Tab, Tabs, Colors } from '@blueprintjs/core';
import styled from 'styled-components';

import CIFilterView from '../Components/CIFilterView';
import AISearchView from '../Components/AISearchView';

import { UserContext } from '../Contexts/UserContext';
import NetworkSettings from '../Hooks/NetworkSettings';

const CompetitiveIntelligenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.h2`
  color: #6c757d;
  font-size: 1.5rem;
`;

const TabsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  display: flex;
  flex-direction: column;

  .bp5-tab-panel {
    flex: 1;
    overflow: hidden;
  }

  .bp5-tab-list {
    background-color: #f8f9fa;
    border-radius: 3px;
  }

  .bp5-tab {
    font-size: 16px;
    padding: 10px 20px;
  }

  .bp5-tab[aria-selected='true'] {
    background-color: #4a6741;
    color: ${Colors.BLUE3};
  }
`;

const TabContent = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
`;

const CompetitiveIntelligenceViewNew = () => {
  const projectName = useSelector((state) => state.project.projectName);
  const [activeTabId, setActiveTabId] = useState('filter-search');

  const handleTabChange = (newTabId) => {
    setActiveTabId(newTabId);
  };

  const { organizationId } = useContext(UserContext);

  if (NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS !== organizationId) {
    return <Navigate to="/" replace />;
  }

  return (
    <CompetitiveIntelligenceContainer className="competitive-intelligence-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Competitive Landscaping</PageTitle>
        <PageSubtitle className="page-subtitle">Project: {projectName}</PageSubtitle>
      </PageHeader>

      <TabsContainer className="tabs-container">
        <StyledTabs
          id="competitive-intelligence-tabs"
          selectedTabId={activeTabId}
          onChange={handleTabChange}
          animate={true}
          large={true}
        >
          <Tab
            id="filter-search"
            title="Filter Search"
            panel={
              <TabContent>
                <CIFilterView />
              </TabContent>
            }
            icon="filter"
          />
          <Tab id="ai-search" title="AI Search" panel={<AISearchView />} icon="clean" />
        </StyledTabs>
      </TabsContainer>
    </CompetitiveIntelligenceContainer>
  );
};

export default CompetitiveIntelligenceViewNew;
