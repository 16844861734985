import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import styled from 'styled-components';

const MarkdownContainer = styled.div`
  height: 100%;
`;

const StreamingMarkdown = ({ content }) => {
  const [renderedContent, setRenderedContent] = useState([]);

  useEffect(() => {
    const processContent = (text) => {
      if (!text) return [];
      // Check if this is the citations section
      if (text.includes('Citations:') || text.includes('Document Citations:')) {
        // Handle both types of citation headers
        let preCitations, citations;
        if (text.includes('Document Citations:')) {
          [preCitations, citations] = text.split('Document Citations:');
        } else {
          [preCitations, citations] = text.split('Citations:');
        }

        // Handle both [1] style and numbered list style citations
        const citationLines = citations
          .trim()
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line.length > 0); // Keep non-empty lines

        // Process pre-citations text using the block method
        const processedPreCitations = processBlock(preCitations);

        // Use the same citation header that was in the original text
        const citationHeader = text.includes('Document Citations:') ? '#### Document Citations:' : '#### Citations:';

        return [...processedPreCitations, citationHeader, '', ...citationLines].filter((line) => line !== null);
      }

      return processBlock(text);
    };

    const processBlock = (text) => {
      if (!text) return [];
      // Split into blocks at headers or double newlines
      const blocks = text.split(/\n(?=#{1,6}\s|$\n)/);
      return blocks
        .map((block) => {
          if (block.trim()) {
            // If the block contains a list
            if (block.includes('\n-') || block.trim().startsWith('-')) {
              // Preserve the entire block with proper indentation
              return block
                .split('\n')
                .map((line) => {
                  const leadingSpaces = line.match(/^\s*/)[0].length;
                  const trimmedLine = line.trimLeft();
                  // Ensure proper markdown list indentation (2 spaces per level)
                  if (trimmedLine.startsWith('-')) {
                    const level = Math.floor(leadingSpaces / 2);
                    return '  '.repeat(level) + trimmedLine;
                  }
                  return line.trimRight();
                })
                .join('\n');
            }
            // Process other content as before
            return block.trim();
          }
          return '';
        })
        .filter(Boolean);
    };

    setRenderedContent(processContent(content));
  }, [content]);

  return (
    <MarkdownContainer className="markdown-container">
      {renderedContent.map((block, index) => (
        <ReactMarkdown
          key={index}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => <h1 style={{ fontSize: '2em', marginBottom: '0.5em' }} {...props} />,
            h2: ({ node, ...props }) => (
              <h2 style={{ fontSize: '1.8em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: '1.5em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h4: ({ node, ...props }) => (
              <h4 style={{ fontSize: '1.3em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h5: ({ node, ...props }) => (
              <h5 style={{ fontSize: '1.1em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h6: ({ node, ...props }) => (
              <h6 style={{ fontSize: '0.9em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            p: ({ node, ...props }) => <span style={{ display: 'block' }} {...props} />,
            pre: ({ node, ...props }) => (
              <pre style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '4px', overflowX: 'auto' }}>
                {props.children}
              </pre>
            ),
            ul: ({ node, ...props }) => (
              <ul
                style={{
                  marginTop: '0.5em',
                  marginBottom: '0.5em',
                  paddingLeft: '1.5em',
                  listStyleType: 'disc',
                }}
                {...props}
              />
            ),
            ol: ({ node, ...props }) => (
              <ol style={{ marginTop: '0.5em', marginBottom: '0.5em', paddingLeft: '1.5em' }} {...props} />
            ),
            li: ({ node, ...props }) => <li style={{ marginBottom: '0.25em' }} {...props} />,
            code: ({ node, inline, ...props }) =>
              inline ? (
                <code style={{ backgroundColor: '#f0f0f0', padding: '2px 4px', borderRadius: '4px' }} {...props} />
              ) : (
                <pre style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '4px', overflowX: 'auto' }}>
                  <code {...props} />
                </pre>
              ),
          }}
        >
          {block}
        </ReactMarkdown>
      ))}
    </MarkdownContainer>
  );
};

export default StreamingMarkdown;
