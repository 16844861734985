import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { Button as BlueprintButton } from '@blueprintjs/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import axios from 'axios';
import NetworkSettings from '../Hooks/NetworkSettings';

const UploadButton = styled(BlueprintButton)`
  margin-top: 10px;
`;

const CardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

const CenteredCard = styled(Card)`
  margin: 0 auto;
  max-width: 800px;
`;

const CenteredCardBody = styled(Card.Body)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; // Adjust this value as needed
`;

const CenteredCardTitle = styled(Card.Title)`
  text-align: center;
`;

const ErrorIcon = styled(FaTimesCircle)`
  color: red;
  margin-right: 10px;
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const ProjectTitle = styled.h1`
  color: var(--sleuth-green);
`;

const SuccessIcon = styled(FaCheckCircle)`
  color: green;
  margin-right: 10px;
`;

const UploadForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const UploadStatus = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

function ModuleCard({ name, to }) {
  return (
    <CardLink to={to}>
      <Card className="mb-3">
        <CenteredCardBody>
          <CenteredCardTitle>{name}</CenteredCardTitle>
        </CenteredCardBody>
      </Card>
    </CardLink>
  );
}

function ProjectView() {
  const { projectName, modules } = useSelector((state) => state.project);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const isNonProduction =
    NetworkSettings.SERVER_URL.includes('localhost') || NetworkSettings.SERVER_URL.includes('staging');

  const getModuleRoute = (type) => {
    switch (type) {
      case 'intelligence':
        return '/intelligence';
      case 'knowledge':
        return '/knowledge';
      case 'search':
        return '/search';
      case 'drugs':
        return '/drug';
      case 'trial-benchmark':
        return '/trial-benchmark';
      default:
        return '/';
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setUploadStatus(null);
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (file) {
      setIsUploading(true);
      setUploadStatus(null);
      setError(null);

      const formData = new FormData();
      formData.append('file', file);

      try {
        await axios.post(`${NetworkSettings.SERVER_URL}/upload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        });
        setUploadStatus('success');
      } catch (error) {
        console.error('Upload failed:', error);
        setError(`Failed to upload ${file.name}: ${error.message}`);
        setUploadStatus('error');
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ProjectContainer>
        <Row className="mt-4">
          <Col>
            <ProjectTitle>{projectName}</ProjectTitle>
            {isNonProduction && (
              <>
                <Button variant="outline-success" className="mt-2">
                  <i className="fas fa-plus"></i> Invite
                </Button>
                <Button variant="success" className="mt-2 ml-2">
                  <i className="fas fa-plus"></i> Share
                </Button>
              </>
            )}
          </Col>
        </Row>

        {isNonProduction && (
          <Row className="mt-4">
            <Col>
              <CenteredCard style={{ minHeight: '300px' }}>
                <Card.Body>
                  <CenteredCardTitle>Modules</CenteredCardTitle>
                  {modules.length === 0 ? (
                    <Card.Text className="text-center">Add a module from the left sidebar to get started</Card.Text>
                  ) : (
                    <Row>
                      {modules.map((module, index) => (
                        <Col md={4} key={index}>
                          <ModuleCard name={module.name} to={getModuleRoute(module.type)} />
                        </Col>
                      ))}
                    </Row>
                  )}
                </Card.Body>
              </CenteredCard>
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col>
            <CenteredCard>
              <Card.Body>
                <CenteredCardTitle>Upload documents to project</CenteredCardTitle>
                <UploadForm onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control type="file" onChange={handleFileChange} ref={fileInputRef} />
                  </Form.Group>
                  <UploadButton intent="success" type="submit" disabled={!file || isUploading}>
                    {isUploading ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Uploading...
                      </>
                    ) : (
                      'Upload'
                    )}
                  </UploadButton>
                  {uploadStatus === 'success' && (
                    <UploadStatus>
                      <SuccessIcon />
                      <span>Uploaded {file.name}</span>
                    </UploadStatus>
                  )}
                  {uploadStatus === 'error' && (
                    <UploadStatus>
                      <ErrorIcon />
                      <span>{error}</span>
                    </UploadStatus>
                  )}
                </UploadForm>
              </Card.Body>
            </CenteredCard>
          </Col>
        </Row>
        {isNonProduction && (
          <Row className="mt-4">
            <Col>
              <CenteredCard>
                <Card.Body>
                  <CenteredCardTitle>Try one of these templates for inspiration</CenteredCardTitle>
                  <Row className="mt-4">
                    {[
                      { name: 'Competitive landscape for a select indication', to: '/template1' },
                      { name: 'Differentiation analysis for a select asset', to: '/template2' },
                      { name: 'High-dimensional simulation for a select trial', to: '/template3' },
                    ].map((template, index) => (
                      <Col md={4} key={index}>
                        <CardLink to={template.to}>
                          <Card>
                            <CenteredCardBody>
                              <CenteredCardTitle>{template.name}</CenteredCardTitle>
                            </CenteredCardBody>
                          </Card>
                        </CardLink>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </CenteredCard>
            </Col>
          </Row>
        )}
      </ProjectContainer>
    </DndProvider>
  );
}

export default ProjectView;
