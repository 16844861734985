import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Tag, Icon, Colors, HTMLTable, Intent } from '@blueprintjs/core';
import SponsorLogos from './Sub/SponsorLogos';

const ColumnToggleButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const ResultsTable = styled(HTMLTable)`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 4px;

  word-break: auto-phrase;
  white-space: break-spaces;
`;

const TableContainer = styled.div`
  width: ${(props) => (props.$limitTableWidth ? '95%' : '100%')};
  margin-top: 20px;
`;

const TrialLink = styled(Link)`
  color: ${Colors.TURQUOISE3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// ------------------------------------------------------------------------------------------------------------------------

// For column names
const formatColumnName = (columnName) => {
  if (columnName === 'nct_id') {
    return 'NCT ID';
  }
  return columnName
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// For sponsor names
const formatSponsorNames = (sponsorNames) => {
  if (typeof sponsorNames === 'string') {
    return sponsorNames.split(',').map((sponsorName) => sponsorName.trim());
  }

  return sponsorNames.map((sponsorName) => {
    if (sponsorName === 'Pfizer New York, NY' || sponsorName === 'pfizer nyc') {
      return 'Pfizer';
    }
    return sponsorName;
  });
};

// For null values
const formatCellValue = (value) => {
  if (value === null || value === 'None' || value === 'N/A') {
    return 'N/A';
  }
  return value;
};

// For trial status
const getStatusColor = (status) => {
  if (!status) return Intent.NONE;
  switch (status.toLowerCase()) {
    case 'completed':
      return Intent.SUCCESS;
    case 'recruiting':
      return Intent.PRIMARY;
    case 'active, not recruiting':
      return Intent.WARNING;
    case 'terminated':
      return Intent.DANGER;
    default:
      return Intent.NONE;
  }
};

// For dates
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

// Specialty rendering
const renderCell = (column, value) => {
  switch (column) {
    case 'trial_name':
      return <TrialLink to={`/trials/${encodeURIComponent(value)}`}>{value}</TrialLink>;

    case 'sponsor':
      return <SponsorLogos sponsors={formatSponsorNames(value || [])} height={25} />;

    case 'nct_id':
      if (!value) return 'N/A';
      return (
        <>
          <a href={`https://clinicaltrials.gov/ct2/show/${value}`} target="_blank" rel="noopener noreferrer">
            {value} <Icon icon="link" />
          </a>
        </>
      );
    case 'trial_status':
      if (value === null || value === undefined) {
        return 'N/A';
      }
      return <Tag intent={getStatusColor(value)}>{value}</Tag>;

    case 'enrollment_actual':
      return <span>{typeof value === 'number' ? value.toLocaleString() : 'N/A'}</span>;
    case 'actual_start_date':
    case 'actual_readout_date':
      return <span>{formatDate(value)}</span>;
    default:
      return formatCellValue(value);
  }
};

// ------------------------------------------------------------------------------------------------------------------------

function RenderTable(tableString, initialVisibleColumns = null) {
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tableData, setTableData] = useState([]);
  const [allColumns, setAllColumns] = useState([]);

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  useEffect(() => {
    let parsedData;
    try {
      parsedData = JSON.parse(tableString);
      if (!parsedData || !parsedData.length) return;
    } catch (e) {
      console.error('Error parsing table data:', e);
      return;
    }

    setTableData(parsedData);
    const columns = Object.keys(parsedData[0]);
    setAllColumns(columns);

    // Always reset visibleColumns when tableString changes
    const defaultVisibleColumns = columns.reduce((acc, column) => {
      acc[column] = initialVisibleColumns ? initialVisibleColumns.includes(column) : true;
      return acc;
    }, {});
    setVisibleColumns(defaultVisibleColumns);
  }, [tableString, initialVisibleColumns]);

  if (!tableData.length) return null;

  const formattedColumns = allColumns.map(formatColumnName);

  return (
    <>
      <ColumnToggleButtonsContainer className="column-toggle-buttons-container">
        {allColumns.map((column) => (
          <Button
            minimal
            intent="primary"
            outlined
            key={column}
            active={visibleColumns[column]}
            onClick={() => toggleColumnVisibility(column)}
            style={{ marginRight: '8px', marginBottom: '8px' }}
          >
            {formatColumnName(column)}
          </Button>
        ))}
      </ColumnToggleButtonsContainer>

      <ResultsTable striped bordered className="results-table">
        <thead>
          <tr>
            {allColumns.map(
              (column, index) => visibleColumns[column] && <th key={index}>{formattedColumns[index]}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {allColumns.map(
                (column, columnIndex) =>
                  visibleColumns[column] && <td key={columnIndex}>{renderCell(column, row[column])}</td>,
              )}
            </tr>
          ))}
        </tbody>
      </ResultsTable>
    </>
  );
}

const ResultTable = ({ tableString, limitTableWidth, initialVisibleColumns = null }) => {
  return (
    <TableContainer $limitTableWidth={limitTableWidth} className="result-table-container">
      {tableString && RenderTable(tableString, initialVisibleColumns)}
    </TableContainer>
  );
};

export default ResultTable;
