import React from 'react';
import styled from 'styled-components';

import UserActivity from '../Components/UserActivity.js';
import Projects from '../Components/Projects.js';

const KnowledgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageContents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const ProjectsCard = styled.div`
  height: 100%;
  flex: 2;
`;

const UserActivityCard = styled.div`
  height: 100%;
  flex: 1;
`;

const KnowledgeView = () => {
  return (
    <KnowledgeContainer>
      <PageTitle>Knowledge Management</PageTitle>
      <PageContents>
        <UserActivityCard>
          <UserActivity className="user-activity" />
        </UserActivityCard>
        <ProjectsCard>
          <Projects className="projects" />
        </ProjectsCard>
      </PageContents>
    </KnowledgeContainer>
  );
};

export default KnowledgeView;
