import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Card,
  Colors,
  FormGroup,
  HTMLTable,
  InputGroup,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Tab,
  Tabs,
  Text,
} from '@blueprintjs/core';

// components
import TrialHeader from '../Components/Sub/TrialHeader.js';
import PatientPopulationConfig from '../Components/Sub/PatientPopulationConfig.js';
import SponsorLogos from '../Components/Sub/SponsorLogos.js';
import Modal from '@mui/material/Modal';

import SelectTrialModal from '../Components/SelectTrialModal.js';

// APIs
import { doDeleteScenario, fetchScenariosByTrialName } from '../Hooks/ScenarioAPI.js';

// contexts
import { GlobalDataContext } from '../Contexts/GlobalDataContext.js';

// utils
import Scenario from '../Data/Scenario.js';
import ARMS from '../Data/Arms.js';
import { updateSessionStorageValue, getSessionStorageValue } from '../Util/SessionStorage.js';
import { showToast } from '../Util/Toaster.js';

const ScenarioBuilderBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const ScenarioBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const ScenarioBuilderHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ScenarioBuilderTrialHeaderWrapper = styled.div`
  flex: 1;
`;

const ScenarioBuilderMainScenarioContainer = styled.div`
  flex: 2.5;
`;

const ScenarioBuilderScenarioControlsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ScenarioBuilderMainCard = styled(Card)``;
const ScenarioBuilderScenarioSimulationList = styled(Card)``;
const ScenarioBuilderScenarioSavedScenarioList = styled(Card)``;
const ScenarioBuilderMainCardTitle = styled.div``;
const ScenarioBuilderMainCardBody = styled.div``;

const ScenarioArmsHTMLTable = styled(HTMLTable)`
  width: 100%;
`;
const ScenarioArmsHTMLTableBody = styled.tbody``;
const ScenarioArmsHTMLTableRow = styled.tr``;
const ScenarioArmsHTMLTableCell = styled.td`
  vertical-align: middle !important;
  padding: 0 !important;
  height: 55px;
  text-align: center !important;
  font-size: 12px;
`;
const ScenarioSwitch = styled(Switch)``;

const ContainerTitle = styled(Text)`
  color: var(--sleuth-green);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
`;
const ScenarioFormGroup = styled(FormGroup)`
  font-size: 14px;

  & .bp5-label {
    width: 150px;
    color: ${Colors.GRAY1};
  }
  & .bp5-form-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;
const ScenarioNameInputGroup = styled(InputGroup)`
  width: 400px;
`;

const ScenarioEndpointButton = styled(Button)`
  width: 400px;
`;
const ScenarioConfigurationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ScenarioConfigurationSimilarTrialsContainer = styled(Card)`
  flex: 1;
`;
const ScenarioConfigurationPatientPopulationContainer = styled(Card)`
  flex: 1.5;
`;

const InstructionText = styled.div`
  margin-bottom: 12px;
`;
const InstructionTextTitle = styled.h6`
  font-size: 12px;
  font-weight: bold;
`;
const InstructionTextBody = styled.p`
  font-size: 12px;
`;

const AnotherTrialButton = styled(Button)`
  margin-top: 12px;
`;

const ScenarioBuilderScenarioSimulationListBody = styled.div``;
const ScenarioBuilderScenarioSavedScenarioListBody = styled.div``;

// --------------------------------------------------------------------------------------------------------

// Builds scenarios for simulation
function ScenarioBuilderView() {
  const { trialName } = useParams();
  const {
    currentTrial,
    setCurrentTrial,
    currentScenario,
    setCurrentScenario,
    currentScenarios,
    setCurrentScenarios,
    savedScenarios,
    setSavedScenarios,
    unsavedScenarios,
    setUnsavedScenarios,
    removedScenarios,
    addRemovedScenario,
    restoreRemovedScenario,
    removeUnsavedScenario,
    addUnsavedScenario,
    updateUnsavedScenario,
    currentEndpoint,
    setCurrentEndpoint,
    isLoading,
    resourceType,
    identifier,
    scenariosToSimulate,
    addToSimulate,
    removeFromSimulate,
    inferEndpoint,
  } = useContext(GlobalDataContext);

  const [scenario, setScenario] = useState(null);
  const [currentArm, setCurrentArm] = useState(ARMS.Intervention);
  const [openSelectTrialModal, setOpenSelectTrialModal] = useState(false);
  const [newScenarioName, setNewScenarioName] = useState('');

  useEffect(() => {
    const loadInitialData = async () => {
      if (!trialName) return;

      // Load saved scenarios from the database only if not already loaded
      if (savedScenarios.length === 0) {
        const scenarios = await fetchScenariosByTrialName(trialName);
        setSavedScenarios(scenarios);
      }

      // Load data from sessionStorage if not available in context
      if (!currentTrial || !currentEndpoint || currentScenarios.length === 0) {
        const storedTrial = getSessionStorageValue('currentTrial');
        const storedEndpoint = getSessionStorageValue('currentEndpoint');
        const storedScenarios = getSessionStorageValue('currentScenarios', []);
        const storedSavedScenarios = getSessionStorageValue('savedScenarios', []);

        if (storedTrial && !currentTrial) setCurrentTrial(storedTrial);
        if (storedEndpoint && !currentEndpoint) setCurrentEndpoint(storedEndpoint);
        if (storedScenarios.length > 0 && currentScenarios.length === 0) setCurrentScenarios(storedScenarios);
        if (storedSavedScenarios.length > 0 && savedScenarios.length === 0) {
          setSavedScenarios((prev) => [...prev, ...storedSavedScenarios]);
        }
      }
    };

    loadInitialData();
  }, [trialName, currentTrial, currentEndpoint, currentScenarios.length, savedScenarios.length]);

  // Keep these separate useEffects
  useEffect(() => {
    if (currentTrial) {
      if (!scenario) {
        setScenario(Scenario.newInstance(currentTrial));
      }

      if (currentTrial.availableEndpoints?.length > 0 && !currentEndpoint) {
        setCurrentEndpoint(currentTrial.availableEndpoints[0]);
      }
    }
  }, [currentTrial, scenario, currentEndpoint]);

  useEffect(() => {
    updateSessionStorageValue('removedScenarios', removedScenarios);
  }, [removedScenarios]);

  const handleAddScenario = () => {
    if (newScenarioName.trim() === '') {
      return;
    }
    const newScenario = Scenario.newInstance(currentTrial);
    newScenario.name = newScenarioName;
    newScenario.endpoint = currentEndpoint;

    // Add slider values to the new scenario
    newScenario.inputs = {
      ...newScenario.inputs,
      sliders: {
        comparator: [...scenario.inputs.sliders.comparator],
        intervention: [...scenario.inputs.sliders.intervention],
      },
    };

    // Check if the new scenario's endpoint matches existing scenarios
    if (scenariosToSimulate.length > 0) {
      const firstScenarioEndpoint = scenariosToSimulate[0].endpoint;
      if (newScenario.endpoint !== firstScenarioEndpoint) {
        showToast({
          message:
            'Cannot add scenario "${newScenario.name}". It has a different endpoint (${newScenario.endpoint}) than the current simulation set (${firstScenarioEndpoint}).',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        return;
      }
    }

    addUnsavedScenario(newScenario);
    resetScenarioForm();
  };

  const handleEditScenario = (updatedScenario) => {
    removeUnsavedScenario(updatedScenario.id);
    addUnsavedScenario(updatedScenario);
  };

  // Delete a scenario from the scenarios to simulate list
  const handleRemoveFromSimulate = (scenario) => {
    if (scenario.id && !scenario.createdAt) {
      removeUnsavedScenario(scenario.id);
    } else {
      addRemovedScenario(scenario.id || scenario.scenarioId);
    }

    removeFromSimulate(scenario);
  };

  const handleAddToSimulate = (scenario) => {
    // Check if the scenario is already in the scenariosToSimulate list
    const isDuplicate = scenariosToSimulate.some((s) =>
      scenario.scenarioId ? s.scenarioId === scenario.scenarioId : s.id === scenario.id,
    );

    if (isDuplicate) {
      showToast({
        message: `Scenario "${scenario.name || scenario.scenarioName || 'Unnamed scenario'}" is already in the simulation list.`,
        intent: 'warning',
        icon: 'warning-sign',
        title: 'Tut, tut',
      });
      return;
    }

    const firstScenarioEndpoint = scenariosToSimulate[0]?.endpoint || currentEndpoint;
    const inferredEndpoint = inferEndpoint(scenario);

    if (!firstScenarioEndpoint) {
      // If this is the first scenario, use the inferred endpoint or fall back to the first available endpoint
      const newEndpoint = inferredEndpoint || Object.keys(scenario.inputs?.simulationArms || {})[0] || currentEndpoint;
      setCurrentEndpoint(newEndpoint);
      addToSimulate({ ...scenario, endpoint: newEndpoint });
      resetScenarioForm();
    } else if (inferredEndpoint === firstScenarioEndpoint) {
      // If the scenario's endpoint matches the first scenario's endpoint, add it
      addToSimulate({ ...scenario, endpoint: firstScenarioEndpoint });
      resetScenarioForm();
    } else {
      // If the scenario's endpoint doesn't match, show an error toast
      showToast({
        message: `Cannot add scenario "${scenario.name || scenario.scenarioName || 'Unnamed scenario'}". It has a different endpoint (${inferredEndpoint}) than the current simulation set (${firstScenarioEndpoint}).`,
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  // Reset the scenario form
  const resetScenarioForm = () => {
    const defaultScenario = Scenario.newInstance(currentTrial, currentEndpoint);
    setScenario(defaultScenario);
    setCurrentScenario(defaultScenario);
    setNewScenarioName('');
    setCurrentArm(ARMS.Intervention);
  };

  const navigate = useNavigate();

  const newScenario = () => {
    setScenario(Scenario.newInstance(currentTrial, currentEndpoint));
    setCurrentArm(ARMS.Intervention);
    setCurrentEndpoint(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const decodedIdentifier = decodeURIComponent(identifier);
  if (!currentTrial || resourceType !== 'scenarioBuilder' || decodedIdentifier !== trialName) {
    return <div>Error: Trial not found or incorrect page.</div>;
  }

  // Delete a scenario from the scenarios to simulate list
  const deleteScenario = (s) => {
    if (s.id && !s.saved) {
      removeUnsavedScenario(s.id);
    } else {
      addRemovedScenario(s.id || s.scenarioId);
    }
  };

  // Delete a saved scenario from the saved scenarios list
  const deleteSavedScenario = async (scenario) => {
    try {
      // Call the API to delete the scenario from the database
      await doDeleteScenario(scenario);

      // Remove the scenario from the savedScenarios list
      setSavedScenarios((prevSavedScenarios) => {
        return prevSavedScenarios.filter((s) => s.scenarioId !== scenario.scenarioId);
      });

      // Add the scenario to the removedScenarios list
      addRemovedScenario(scenario.scenarioId);

      // Remove the scenario from scenariosToSimulate if it's there
      removeFromSimulate(scenario);
    } catch (error) {
      console.error('Error deleting scenario:', error);
    }
  };

  const restoreScenario = (scenarioToRestore) => {
    restoreRemovedScenario(scenarioToRestore.id || scenarioToRestore.scenarioId);

    // Add back to unsavedScenarios if it's not already in savedScenarios
    if (!savedScenarios.some((s) => s.id === scenarioToRestore.id || s.scenarioId === scenarioToRestore.scenarioId)) {
      addUnsavedScenario(scenarioToRestore);
    }

    // Optionally, you might want to update the current scenario
    setScenario(scenarioToRestore);
    setCurrentScenario(scenarioToRestore);
  };

  const loadScenario = (s) => {
    setScenario(s);
  };

  const addTrial = (trial, arm) => {
    if (openSelectTrialModal) setOpenSelectTrialModal(false);

    if (trial) {
      // only add this if we haven't already
      const existingTrial = scenario?.inputs?.simulationArms[currentEndpoint][currentArm].filter(
        (sa) => sa.trialName === trial.trialName,
      );

      if (!existingTrial || existingTrial.length === 0) {
        // Add a trial to the current scenario
        let newScenario = { ...scenario };
        newScenario.inputs.simulationArms[currentEndpoint][currentArm].push({
          sponsor: trial.sponsor,
          trialName: trial.trialName,
          intervention: arm === ARMS.Intervention,
          comparator: arm === ARMS.Comparator,
          interventionOnly: trial.interventionOnly,
          default: false,
        });
        setCurrentScenario(newScenario);
      }
    }
  };
  const handleCloseSelectTrialModal = () => {
    setOpenSelectTrialModal(false);
  };
  const removeCTA = (cta) => {
    let newScenario = { ...scenario };
    newScenario.inputs.simulationArms[currentEndpoint][currentArm] = newScenario.inputs.simulationArms[currentEndpoint][
      currentArm
    ].filter((a) => a.trialName !== cta.trialName);
    setScenario(newScenario);
    setCurrentScenario(newScenario);
  };

  const toggleSimulationArm = (simArm, arm) => {
    let newScenario = { ...scenario };

    // find arm and update it
    for (let a of newScenario.inputs.simulationArms[currentEndpoint][currentArm]) {
      if (a.trialName === simArm.trialName) {
        a[arm] = !a[arm];
        break;
      }
    }

    // save it
    setCurrentScenario(newScenario);
  };

  // Add to simulate scenarios
  const handleSimulate = () => {
    setCurrentScenarios(scenariosToSimulate);
    navigate('/progress');
  };

  // Set current endpoint
  const handleEndpointChange = (endpoint) => {
    setCurrentEndpoint(endpoint);
  };

  const scenarioConfigurationCardContainer = (
    <ScenarioConfigurationContainer className="scenario-config-container">
      <ScenarioConfigurationSimilarTrialsContainer className="scenario-config-similar-trials-container">
        <InstructionText className="instruction-text">
          <InstructionTextTitle className="instruction-text-title">
            Select Similar Trials for each arm
          </InstructionTextTitle>
          <InstructionTextBody className="instruction-text-body">
            These are trials you believe are similar enough to {currentTrial?.trialName} that their results can inform
            the simulated results.
          </InstructionTextBody>
        </InstructionText>

        {scenario && (
          <ScenarioArmsHTMLTable className="scenario-arms-html-table" striped>
            <thead>
              <tr>
                <th scope="col" className="sleuthSize text-center">
                  Company
                </th>
                <th scope="col" className="sleuthSize text-center">
                  Trial Name
                </th>
                <th scope="col" className="sleuthSize text-center">
                  Intervention
                </th>
                <th scope="col" className="sleuthSize text-center">
                  Comparator
                </th>
              </tr>
            </thead>
            <ScenarioArmsHTMLTableBody>
              {scenario?.inputs?.simulationArms?.[currentEndpoint] &&
              scenario?.inputs?.simulationArms?.[currentEndpoint][currentArm] ? (
                scenario.inputs.simulationArms[currentEndpoint][currentArm].map((t) => (
                  <ScenarioArmsHTMLTableRow key={t.trialName}>
                    <ScenarioArmsHTMLTableCell>
                      <SponsorLogos sponsors={t.sponsor} height={12} />
                    </ScenarioArmsHTMLTableCell>
                    <ScenarioArmsHTMLTableCell>{t.trialName}</ScenarioArmsHTMLTableCell>
                    <ScenarioArmsHTMLTableCell>
                      <ScenarioSwitch
                        checked={t.intervention}
                        value={t.intervention}
                        type="checkbox"
                        onChange={() => {
                          toggleSimulationArm(t, ARMS.Intervention);
                        }}
                      />
                    </ScenarioArmsHTMLTableCell>
                    <ScenarioArmsHTMLTableCell>
                      {!t.interventionOnly && (
                        <ScenarioSwitch
                          checked={t.comparator}
                          value={t.comparator}
                          type="checkbox"
                          onChange={() => {
                            toggleSimulationArm(t, ARMS.Comparator);
                          }}
                        />
                      )}
                    </ScenarioArmsHTMLTableCell>
                  </ScenarioArmsHTMLTableRow>
                ))
              ) : (
                <></>
              )}
            </ScenarioArmsHTMLTableBody>
          </ScenarioArmsHTMLTable>
        )}
        <AnotherTrialButton type="button" onClick={() => setOpenSelectTrialModal(true)} fill outlined>
          + Add Another Trial
        </AnotherTrialButton>
      </ScenarioConfigurationSimilarTrialsContainer>
      <ScenarioConfigurationPatientPopulationContainer>
        {scenario && (
          <>
            <InstructionText className="instruction-text">
              <InstructionTextTitle className="instruction-text-title">
                Select Patient Population for each arm
              </InstructionTextTitle>
              <InstructionTextBody className="instruction-text-body">
                Different patient populations have different influences on the potential outcomes of the trial. By
                selecting the patient population, you can explore how those influences affect outcomes.
              </InstructionTextBody>
            </InstructionText>
            <PatientPopulationConfig
              scenario={scenario}
              arm={currentArm}
              setScenario={setCurrentScenario}
              key={scenario?.id}
            />
          </>
        )}
      </ScenarioConfigurationPatientPopulationContainer>
    </ScenarioConfigurationContainer>
  );

  return (
    <ScenarioBuilderContainer className="scenario-builder-container">
      <ScenarioBuilderHeader className="scenario-builder-header">
        <ScenarioBuilderTrialHeaderWrapper className="scenario-builder-trial-header-wrapper">
          <TrialHeader trial={currentTrial} />
        </ScenarioBuilderTrialHeaderWrapper>
      </ScenarioBuilderHeader>

      <ScenarioBuilderBody className="scenario-builder-body">
        <ScenarioBuilderMainScenarioContainer className="scenario-builder-main-scenario-container">
          <ScenarioBuilderMainCard className="scenario-builder-main-card">
            <ScenarioBuilderMainCardTitle className="scenario-builder-main-card-title">
              <ContainerTitle>Configure Scenario</ContainerTitle>

              <ScenarioFormGroup label={'Scenario Name'} labelFor="scenario-name-input" inline fill>
                <ScenarioNameInputGroup
                  id="scenario-name-input"
                  placeholder="Give this scenario a name"
                  type="text"
                  value={scenario?.createdAt ? scenario.scenarioName : scenario?.name || newScenarioName}
                  onChange={(e) => {
                    setScenario({
                      ...scenario,
                      name: e.target.value,
                    });
                    setNewScenarioName(e.target.value);
                  }}
                />
                <Button onClick={handleAddScenario} intent="primary" rightIcon="plus">
                  Add
                </Button>
                <Button onClick={newScenario} rightIcon="refresh">
                  Reset
                </Button>
              </ScenarioFormGroup>

              <ScenarioFormGroup label={'Scenario Endpoint'} labelFor="scenario-endpoint-input" inline fill>
                {currentTrial?.availableEndpoints?.length > 0 ? (
                  currentTrial?.availableEndpoints?.length > 1 ? (
                    <Popover
                      content={
                        <Menu className="scenario-endpoint-menu">
                          {currentTrial?.availableEndpoints?.map((endpoint) => (
                            <MenuItem
                              key={endpoint}
                              text={endpoint.toUpperCase()}
                              onClick={() => handleEndpointChange(endpoint)}
                            />
                          ))}
                        </Menu>
                      }
                      placement="bottom"
                      minimal
                      matchTargetWidth
                    >
                      <ScenarioEndpointButton
                        alignText="left"
                        rightIcon="caret-down"
                        text={
                          currentEndpoint != null && currentEndpoint !== ''
                            ? currentEndpoint?.toUpperCase()
                            : 'Select an available endpoint for visualization'
                        }
                      />
                    </Popover>
                  ) : (
                    <ScenarioEndpointButton
                      alignText="left"
                      rightIcon="caret-down"
                      text={currentEndpoint?.toUpperCase()}
                      disabled
                    />
                  )
                ) : (
                  <ScenarioEndpointButton alignText="left" disabled text="No endpoints available" />
                )}
              </ScenarioFormGroup>
            </ScenarioBuilderMainCardTitle>

            <ScenarioBuilderMainCardBody className="scenario-builder-main-card-body">
              <Tabs
                id="scenario-builder-tabs"
                animate
                large
                onChange={setCurrentArm}
                selectedTabId={currentArm}
                key={`tabs-${currentEndpoint}`}
              >
                <Tab
                  id="intervention"
                  title="Intervention Arm"
                  panel={scenarioConfigurationCardContainer}
                  icon="selection"
                />
                <Tab
                  id="comparator"
                  title="Comparator Arm"
                  panel={scenarioConfigurationCardContainer}
                  icon="comparison"
                />
              </Tabs>
            </ScenarioBuilderMainCardBody>
          </ScenarioBuilderMainCard>
        </ScenarioBuilderMainScenarioContainer>

        <ScenarioBuilderScenarioControlsContainer>
          <ScenarioBuilderScenarioSimulationList>
            <ContainerTitle>Scenarios to Simulate</ContainerTitle>
            <ScenarioBuilderScenarioSimulationListBody>
              {scenariosToSimulate.length > 0 ? (
                <>
                  <table className="table table-sm table-hover">
                    <tbody>
                      {scenariosToSimulate.map((s) => (
                        <tr key={s.id || s.scenarioId}>
                          <td>
                            <a
                              onClick={() => {
                                loadScenario(s);
                              }}
                              className="text-secondary text-decoration-none"
                            >
                              {s.scenarioName || s.name}
                            </a>
                          </td>
                          <td>
                            <Button
                              minimal
                              icon="edit"
                              onClick={() => {
                                handleEditScenario(s);
                              }}
                            />
                            <Button
                              minimal
                              icon="trash"
                              onClick={() => {
                                handleRemoveFromSimulate(s);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <a onClick={handleSimulate} className="btn btn-warning sleuthActionButton">
                    Run All Scenarios
                  </a>
                </>
              ) : (
                <>
                  <p className="sleuthSize">Add scenarios using the form on the left.</p>
                </>
              )}
            </ScenarioBuilderScenarioSimulationListBody>
          </ScenarioBuilderScenarioSimulationList>
          <ScenarioBuilderScenarioSavedScenarioList>
            <ContainerTitle>Saved Scenarios</ContainerTitle>
            <ScenarioBuilderScenarioSavedScenarioListBody>
              {savedScenarios?.length > 0 ? (
                <>
                  <p className="sleuthSize">
                    These are scenarios you have previously saved. Click on one to load it as part of your current
                    simulation set.
                  </p>

                  <table className="table table-sm table-hover">
                    <tbody>
                      {savedScenarios.map((ss) => {
                        return (
                          <tr key={ss.scenarioId || ss.id}>
                            <td>
                              <a
                                onClick={() => {
                                  handleAddToSimulate(ss);
                                }}
                                className="text-secondary text-decoration-none"
                              >
                                {ss.scenarioName || ss.name}
                              </a>
                            </td>
                            <td>
                              <a
                                onClick={() => {
                                  deleteSavedScenario(ss);
                                }}
                              >
                                <i title="Delete" className="bi bi-trash text-secondary"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <p className="sleuthSize">After you run a simulation, you can save it and load it here later.</p>
                </>
              )}
            </ScenarioBuilderScenarioSavedScenarioListBody>
          </ScenarioBuilderScenarioSavedScenarioList>
        </ScenarioBuilderScenarioControlsContainer>

        <Modal
          open={openSelectTrialModal}
          onClose={handleCloseSelectTrialModal}
          aria-labelledby="select-trial-modal-title"
          aria-describedby="select-trial-modal-description"
        >
          <div>
            <SelectTrialModal
              scenario={scenario}
              handler={addTrial}
              currentArm={currentArm}
              currentEndpoint={currentEndpoint}
            />
          </div>
        </Modal>
      </ScenarioBuilderBody>
    </ScenarioBuilderContainer>
  );
}

export default ScenarioBuilderView;
