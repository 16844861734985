import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// components
import LeftNav from '../Components/Nav/LeftNav';
import 'react-chatbot-kit/build/main.css';
import '../Components/Chatbot.css';

import { UserContext } from '../Contexts/UserContext';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
`;

const LeftSidebar = styled.aside`
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; // Add this line
  transition: width 0.3s ease-in-out;
  width: ${(props) => (props.$isOpen ? '250px' : '50px')};
  flex-shrink: 0; // Add this line
`;

const MainContent = styled.main`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  transition: left 0.3s ease-in-out;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

// ---------------------------------------------------------------------------------------------------------------------

function Layout() {
  const { isAuthenticated } = useContext(UserContext);
  const location = useLocation();
  const currentPath = location.pathname;

  const [issidebaropen, setIsSidebarOpen] = useState(() => {
    const storedState = localStorage.getItem('sidebarOpen');
    return isAuthenticated && storedState !== null ? JSON.parse(storedState) : true;
  });
  const isLoginPath = currentPath === '/login';

  // Toggle the sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem('sidebarOpen', JSON.stringify(newState));
      return newState;
    });
  };

  // Set the sidebar state based on the user's authentication and path
  useEffect(() => {
    if (isAuthenticated && !isLoginPath) {
      setIsSidebarOpen(true);
      localStorage.setItem('sidebarOpen', JSON.stringify(true));
    } else if (!isAuthenticated || isLoginPath) {
      setIsSidebarOpen(false);
      localStorage.setItem('sidebarOpen', JSON.stringify(false));
    }
  }, [isAuthenticated, isLoginPath]);

  return (
    <LayoutContainer className="layout-container">
      <MainContentWrapper className="main-content-wrapper">
        {isAuthenticated && !isLoginPath && (
          <LeftSidebar className="left-sidebar" $isOpen={issidebaropen}>
            <LeftNav $isOpen={issidebaropen} toggleSidebar={toggleSidebar} />
          </LeftSidebar>
        )}
        <MainContent className={`${isAuthenticated ? 'logged-in' : ''} ${issidebaropen ? 'sidebar-open' : ''}`}>
          <Outlet />
        </MainContent>
      </MainContentWrapper>
    </LayoutContainer>
  );
}

export default Layout;
