import React, { useState, useCallback } from 'react';
import { Button, Callout, Card, MenuItem, Colors, Icon } from '@blueprintjs/core';
import { debounce } from 'lodash';
import { Suggest } from '@blueprintjs/select';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import NetworkSettings from '../Hooks/NetworkSettings';
import { addRecentSearch, removeRecentSearch } from '../store/trialSearchSlice';
import { snakeToCamel } from '../Util/CaseConvert.js';

const API_URL = NetworkSettings.SERVER_URL + '/clinical-trial-search-new';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--sleuth-green);
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const TrialSearchBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 96px;
`;

const TrialSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const TrialSearchHeader = styled.div``;

const TrialSearchSubtitle = styled.p`
  color: #666;
`;

const TrialSearchSuggest = styled(Suggest)`
  width: 100%;

  & .bp5-input {
    border-radius: 2px;
    border-top: 2px solid ${Colors.GREEN3};
    height: 40px;
  }
`;

const TrialSearchTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const RecentSearchCallout = styled(Callout)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

const RecentSearchCalloutTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RecentSearchesCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
`;

const SearchNameText = styled.h5`
  font-size: 1rem;
  font-weight: bold;
`;

const SearchResultsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 0.8;
`;

const SearchTimestampText = styled.p`
  font-size: 0.8rem;
  color: #666;
`;

const SearchWrapper = styled.div`
  flex: 1;
`;

const TrialSearchView = () => {
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectName = useSelector((state) => state.project.projectName);
  const recentSearches = useSelector((state) => state.trialSearch.recentSearches);

  const clearSearchResults = () => {
    setSearchResults([]);
  };

  const handleRemoveRecentSearch = (searchName) => {
    dispatch(removeRecentSearch(searchName));
  };

  const debouncedHandleSearch = useCallback(
    debounce((query) => {
      handleSearch(query);
    }, 300),
    [],
  );

  const handleSearch = async (query) => {
    if (query.length < 2) return;
    try {
      const response = await fetch(API_URL + `?query=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const results = await response.json();
      const camelCaseResults = snakeToCamel(results);

      // Filter results to keep only unique trialCoreUuid
      const uniqueResults = camelCaseResults.reduce((acc, current) => {
        if (current && current.trialName) {
          const x = acc.find((item) => item.trialName === current.trialName);
          if (!x) {
            return acc.concat([current]);
          }
        }
        return acc;
      }, []);

      setSearchResults(uniqueResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleSelect = (selected) => {
    if (selected && selected.trialName && selected.nctId) {
      dispatch(
        addRecentSearch({
          name: selected.trialName,
          nctId: selected.nctId,
          timestamp: new Date().toISOString(),
        }),
      );
      navigateToTrial(selected.trialName);
    }
  };

  const navigateToTrial = (trialName) => {
    navigate(`/trials/${trialName}`);
  };

  const renderMenuItemChildren = (trial) => {
    return (
      <MenuItem
        key={trial.trialName}
        text={`${trial.trialName} - (${trial.nctId})`}
        onClick={() => handleSelect(trial)}
      />
    );
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const searchTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - searchTime) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };

  return (
    <TrialSearchContainer className="trial-search-container">
      <TrialSearchHeader className="trial-search-header">
        <TrialSearchTitle>Search for a clinical trial</TrialSearchTitle>
        <TrialSearchSubtitle>
          For project <b>{projectName}</b>, search for a trial by name.
        </TrialSearchSubtitle>
      </TrialSearchHeader>

      <TrialSearchBody className="trial-search-body">
        <SearchWrapper>
          <TrialSearchSuggest
            id="trial-search"
            placeholder="Search for a trial..."
            items={searchResults || []}
            itemRenderer={renderMenuItemChildren}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={handleSelect}
            onQueryChange={(query) => debouncedHandleSearch(query)}
            popoverProps={{ matchTargetWidth: true, minimal: true }}
            inputProps={{ placeholder: 'Search for a trial...' }}
            resetOnClose={true}
            resetOnSelect={true}
            onClose={clearSearchResults}
          />
        </SearchWrapper>

        <SearchResultsListContainer>
          {recentSearches.length > 0 && (
            <RecentSearchesCard>
              <CardTitle>
                <Icon icon="history" /> Recent Searches
              </CardTitle>

              <CardBody>
                {recentSearches.slice(0, 3).map((search, index) => (
                  <RecentSearchCallout key={index} icon="search">
                    <RecentSearchCalloutTitle>
                      <SearchNameText>{search.name}</SearchNameText>
                      <SearchTimestampText>{formatTimestamp(search.timestamp)}</SearchTimestampText>
                    </RecentSearchCalloutTitle>

                    <ButtonContainer>
                      <Button intent="primary" onClick={() => navigateToTrial(search.name)}>
                        Search Again
                      </Button>
                      <Button icon="cross" minimal onClick={() => handleRemoveRecentSearch(search.name)} />
                    </ButtonContainer>
                  </RecentSearchCallout>
                ))}
              </CardBody>
            </RecentSearchesCard>
          )}
        </SearchResultsListContainer>
      </TrialSearchBody>
    </TrialSearchContainer>
  );
};

export default TrialSearchView;
