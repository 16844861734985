import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Colors, Spinner } from '@blueprintjs/core';
import styled from 'styled-components';

import NetworkSettings from '../Hooks/NetworkSettings';
import StreamingMarkdown from '../Components/StreamingMarkdown';
import ResultTable from '../Components/ResultTable';
import { parseDeliverableContents } from './AutonomousAIRepositoryView';

const API_URL = NetworkSettings.SERVER_URL;

const DeliverableViewContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContainerBody = styled.div``;

const ImageContainer = styled.div`
  width: 100%;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const DeliverableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeliverableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  background-color: ${Colors.WHITE};
`;

const DeliverableDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const DeliverableDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const DeliverableDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const DeliverableDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`;

const DeliverableDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  border-radius: 4px;
  padding: 12px;
  height: auto;
  overflow: visible;
  flex: 0 0 auto;
`;

const TableContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 12px 0;
`;

const AutonomousAIDeliverableView = () => {
  const { deliverableID } = useParams();

  const [deliverableData, setDeliverableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const targetRef = useRef(null);

  useEffect(() => {
    const fetchDeliverableData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_URL}/get-deliverable/${deliverableID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok for deliverable ${deliverableID}`);
        }
        const data = await response.json();
        const parsedData = {
          ...data,
          data: [
            {
              ...data.data[0],
              contents: parseDeliverableContents(data),
            },
          ],
        };
        setDeliverableData(parsedData);
      } catch (error) {
        console.error('Error fetching deliverable data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDeliverableData();
  }, [deliverableID]);

  return (
    <DeliverableViewContainer className="deliverable-view-container">
      <ContainerHeader className="container-header">
        <ImageContainer className="image-container">
          <a href="https://sleuthinsights.com/" target="_blank" rel="noreferrer">
            <img src="/img/logos/sleuth_logo.png" alt="Sleuth Insights" />
          </a>
        </ImageContainer>
      </ContainerHeader>

      <ContainerBody className="container-body">
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <DeliverableDetailsContainer className="deliverable-details-container">
            <DeliverableDetailsWrapper className="deliverable-details-wrapper" ref={targetRef}>
              <DeliverableDetailsHeader className="deliverable-details-header">
                <DeliverableDetailsTitle>{deliverableData?.data?.[0]?.name}</DeliverableDetailsTitle>
                <DeliverableDetailsDate>
                  {new Date(deliverableData?.data?.[0]?.updated_at).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </DeliverableDetailsDate>
              </DeliverableDetailsHeader>

              <DeliverableDetailsBody className="deliverable-details-body">
                <DeliverableDetailsLongAnswer className="deliverable-details-long-answer">
                  {deliverableData?.data?.[0]?.contents?.text_markdown && (
                    <StreamingMarkdown content={deliverableData?.data?.[0]?.contents?.text_markdown} />
                  )}
                </DeliverableDetailsLongAnswer>

                <TableContainer className="table-container">
                  {deliverableData?.data?.[0]?.contents?.table_string && (
                    <ResultTable
                      tableString={deliverableData?.data?.[0]?.contents?.table_string}
                      initialVisibleColumns={[
                        'drug_name',
                        'sponsor',
                        'nct_id',
                        'trial_name',
                        'trial_status',
                        'efficacy_data',
                        'safety_data',
                      ]}
                      limitTableWidth={false}
                    />
                  )}
                </TableContainer>
              </DeliverableDetailsBody>
            </DeliverableDetailsWrapper>
          </DeliverableDetailsContainer>
        )}
      </ContainerBody>
    </DeliverableViewContainer>
  );
};

export default AutonomousAIDeliverableView;
