import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const ViewContainer = styled.div`
  padding: 12px 24px 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// ---------------------------------------------------------------------------------------------------------------------

function AutonomousAIView() {
  const navigate = useNavigate();

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Autonomous AI</PageTitle>
        <PageSubtitle className="page-subtitle">
          Autonomous AI is a feature that allows you to create and manage autonomous AI agents. These agents can be used
          to perform a variety of tasks, such as searching for information, summarizing documents, and more.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <ActionsContainer className="actions-container">
          <Button
            large
            icon="add-to-artifact"
            text="Create new task"
            intent="primary"
            onClick={() => navigate('/autonomous-ai/create-task')}
          />
          <Button
            large
            icon="panel-stats"
            text="Confirm tasks"
            intent="primary"
            onClick={() => navigate('/autonomous-ai/workspace')}
          />
          <Button
            large
            icon="git-repo"
            text="View deliverables repository"
            intent="primary"
            onClick={() => navigate('/autonomous-ai/repository')}
          />
        </ActionsContainer>
      </PageBody>
    </ViewContainer>
  );
}

export default AutonomousAIView;
