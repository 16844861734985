import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, Colors, Drawer } from '@blueprintjs/core';

import { PiPaperPlaneTiltDuotone, PiUserDuotone, PiUploadDuotone, PiFileImageDuotone } from 'react-icons/pi';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import axios from 'axios';

import NetworkSettings from '../Hooks/NetworkSettings';
import sherlockLine from '../sherlock-line.svg';
import { showToast } from '../Util/Toaster.js';

const API_URL = NetworkSettings.SERVER_URL;

const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
`;

const dotAnimation = keyframes`
  0%, 33%, 66% { content: '   '; }
  8%, 41%, 74% { content: '.  '; }
  16%, 49%, 82% { content: '.. '; }
  24%, 57%, 90% { content: '...'; }
  32%, 65%, 98% { content: '   '; }
  100% { content: '   '; }
`;

const progressAnimation = keyframes`
  0% { content: 'I require time to think'; }
  14.28% { content: 'What a curious mind you have!'; }
  28.57% { content: 'There is a mystery afoot'; }
  42.85% { content: 'Confound it all!'; }
  57.14% { content: 'No query can baffle me'; }
  71.42% { content: "I am a master of deduction"; }
  85.71% { content: "This is quite the stimulating case"; }
  100% { content: "Elementary!"; }
`;

const ChatBody = styled.div`
  flex-grow: 1;
  padding: 24px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 36px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ChatbotContainer = styled.div`
  font-size: 0.9rem;
`;

const ChatHeader = styled.div`
  border-bottom: 12px solid var(--sleuth-green);
`;

const ChatInput = styled.textarea`
  flex-grow: 1;
  padding: 8px 40px 12px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 0.9rem;
  resize: none;
  height: ${(props) => (props.value.includes('\n') ? 'auto' : '40px')};
  min-height: 40px;
  line-height: 1.4;
  overflow: ${(props) => (props.value.includes('\n') ? 'auto' : 'hidden')};
  vertical-align: top;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ChatInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px;
`;

const ChatInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ChatInputOptionsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const IconButton = styled(Button)`
  transition: ease-in-out 0.2s;
  cursor: pointer;

  margin: 0 !important;
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;

  .bp5-icon {
    width: 12px !important;
    height: 12px !important;
    color: ${Colors.GRAY4} !important;

    svg {
      width: 12px !important;
      height: 12px !important;
      fill: ${(props) => props.color || Colors.GRAY4} !important;
    }
  }
`;

const LoadingBar = styled.div`
  display: inline-block;
  font-family: monospace;
  position: relative;

  &::after {
    content: 'Thinking...';
    animation: ${progressAnimation} 28s steps(8, end) forwards;
  }

  &::before {
    content: '.  ';
    position: absolute;
    left: 100%;
    margin-left: 4px;
    animation:
      ${dotAnimation} 1.8s steps(4, end) infinite,
      ${bounceAnimation} 0.6s ease-in-out infinite;
  }
`;

const Message = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.$isUser ? 'row' : 'row-reverse')};
  gap: 12px;
  align-items: flex-end;
`;

const MessageContent = styled.div`
  background-color: ${(props) => (props.$isUser ? '#548B59' : '#f1f1f1')};
  color: ${(props) => (props.$isUser ? 'white' : 'black')};
  padding: ${(props) => (props.$isUser ? '16px 24px 24px 24px' : '16px 24px 12px 24px')};
  border-radius: 10px;
  display: inline-block;

  width: 96%;
  min-height: 60px;
  overflow-wrap: break-word;

  font-size: 0.9rem;

  /* Markdown Styles */
  p {
    margin-bottom: 0.5em;
  }

  ul,
  ol {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-left: 1.5em;
  }

  li {
    margin-bottom: 0.25em;
  }

  code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background-color: #f0f0f0;
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
  }
`;

const MessageIcon = styled.div`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  background-color: ${(props) => (props.$isUser ? 'var(--sleuth-green)' : 'var(--aureolin)')};
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageOptionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px !important;
  align-items: center;
`;

const SendIconButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--sleuth-green);
  padding: 5px;

  &:hover {
    color: #2a4721;
  }
`;

const SherlockIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const SherlockIconChat = styled.img`
  width: 24px;
  height: 24px;
`;

const SleuthBotButton = styled(Button)`
  color: ${Colors.WHITE} !important;
  background-color: var(--aureolin) !important;
  box-shadow: none !important;
  padding: 4px 8px !important;

  border-radius: 50%;
`;

const SleuthBotDrawer = styled(Drawer)`
  z-index: 2000;
`;

const SpinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--sleuth-green);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${SpinnerAnimation} 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
`;

const UploadButton = styled.button`
  background-color: transparent;
  color: var(--sleuth-green);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
`;

const UploadText = styled.span`
  margin-left: 8px;
  font-size: 12px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const StreamingMarkdown = ({ content }) => {
  const [renderedContent, setRenderedContent] = useState([]);

  useEffect(() => {
    const words = content.split(' ');
    const newRenderedContent = [];
    let currentLine = '';

    words.forEach((word, index) => {
      currentLine += word + ' ';

      if (word.endsWith('\n') || index === words.length - 1) {
        newRenderedContent.push(currentLine.trim());
        currentLine = '';
      }
    });

    setRenderedContent(newRenderedContent);
  }, [content]);

  return (
    <>
      {renderedContent.map((line, index) => (
        <ReactMarkdown
          key={index}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={{
            p: ({ node, ...props }) => <span style={{ display: 'block' }} {...props} />,
            ul: ({ node, ...props }) => (
              <ul style={{ marginTop: '0.5em', marginBottom: '0.5em', paddingLeft: '1.5em' }} {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol style={{ marginTop: '0.5em', marginBottom: '0.5em', paddingLeft: '1.5em' }} {...props} />
            ),
            li: ({ node, ...props }) => <li style={{ marginBottom: '0.25em' }} {...props} />,
            code: ({ node, inline, ...props }) =>
              inline ? (
                <code style={{ backgroundColor: '#f0f0f0', padding: '2px 4px', borderRadius: '4px' }} {...props} />
              ) : (
                <pre style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '4px', overflowX: 'auto' }}>
                  <code {...props} />
                </pre>
              ),
          }}
        >
          {line}
        </ReactMarkdown>
      ))}
    </>
  );
};

// ---------------------------------------------------------------------------------------------------------------------

function SleuthBot() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { content: "Greetings! I'm Sleuthlock. What inquiries have brought you here?", isUser: false },
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [messageFeedback, setMessageFeedback] = useState({});

  const chatBodyRef = useRef(null);
  const fileInputRef = useRef(null);

  const [isTyping, setIsTyping] = useState(false);

  // Handle chat body scroll
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  // Handle message send
  const handleSend = async () => {
    if (inputMessage.trim() === '') return;

    setMessages((prev) => [...prev, { content: inputMessage, isUser: true }]);
    setInputMessage('');
    setIsLoading(true);
    setIsTyping(false);

    // Get the entire HTML content of the page
    const pageContext = document.body.innerHTML;

    try {
      const response = await fetch(`${API_URL}/chat/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: inputMessage, pageContext: pageContext }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        accumulatedResponse += chunk;

        if (!isTyping) {
          setIsTyping(true);
        }

        setMessages((prev) => {
          const newMessages = [...prev];
          if (newMessages[newMessages.length - 1].isUser) {
            newMessages.push({ content: accumulatedResponse, isUser: false });
          } else {
            newMessages[newMessages.length - 1].content = accumulatedResponse;
          }
          return newMessages;
        });
      }
    } catch (error) {
      console.error('Error during chat:', error);
      setMessages((prev) => [...prev, { content: 'An error occurred while processing your question.', isUser: false }]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    setIsUploading(true);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if file is PDF
      if (!file.type.match('application/pdf')) {
        showToast({
          message: `"${file.name}" must be a PDF file. Currently only PDF files are supported.`,
          intent: 'warning',
          icon: 'warning-sign',
          title: 'Tut, tut',
        });
        continue; // Skip this file and move to next
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        await axios.post(`${API_URL}/upload/`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        });
        setMessages((prev) => [...prev, { content: `File "${file.name}" uploaded successfully.`, isUser: false }]);
        showToast({
          message: `File "${file.name}" uploaded successfully.`,
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      } catch (error) {
        console.error('Upload failed:', error);
        setMessages((prev) => [...prev, { content: `Failed to upload ${file.name}: ${error.message}`, isUser: false }]);
        showToast({
          message: `Failed to upload ${file.name}: ${error.message}`,
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    }

    setIsUploading(false);
  };

  // Handle message copy
  const handleCopyMessage = (content, messageIndex) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedMessageId(messageIndex);
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  const handleFeedback = (messageIndex, feedbackType) => {
    setMessageFeedback((prev) => {
      const newFeedback = { ...prev };

      // If clicking the same button again, remove the feedback
      if (prev[messageIndex] === feedbackType) {
        delete newFeedback[messageIndex];
      } else {
        // Otherwise set the new feedback
        newFeedback[messageIndex] = feedbackType;
      }

      return newFeedback;
    });
  };

  return (
    <ChatbotContainer>
      <SleuthBotButton onClick={() => setIsOpen(!isOpen)}>
        <SherlockIcon src={sherlockLine} alt="Sherlock" />
      </SleuthBotButton>

      <SleuthBotDrawer isOpen={isOpen} canEscapeKeyClose canOutsideClickClose onClose={() => setIsOpen(false)}>
        <ChatHeader />

        <ChatBody ref={chatBodyRef} className="sleuthbot-chat-body">
          {messages.map((message, index) => (
            <Message key={index} $isUser={message.isUser} className="sleuthbot-message">
              <MessageContent $isUser={message.isUser} className="sleuthbot-message-content">
                {message.isUser ? message.content : <StreamingMarkdown content={message.content} />}

                {!message.isUser && index !== 0 && (
                  <MessageOptionsContainer className="sleuthbot-message-options-container">
                    <IconButton
                      icon={copiedMessageId === index ? 'tick' : 'duplicate'}
                      small
                      minimal
                      onClick={() => handleCopyMessage(message.content, index)}
                      iconOnly
                    />
                    <IconButton
                      icon="thumbs-up"
                      small
                      minimal
                      iconOnly
                      onClick={() => handleFeedback(index, 'positive')}
                      color={messageFeedback[index] === 'positive' ? '#15B371' : Colors.GRAY4}
                    />
                    <IconButton
                      icon="thumbs-down"
                      small
                      minimal
                      iconOnly
                      onClick={() => handleFeedback(index, 'negative')}
                      color={messageFeedback[index] === 'negative' ? '#CD4246' : Colors.GRAY4}
                    />
                  </MessageOptionsContainer>
                )}
              </MessageContent>

              <MessageIcon $isUser={message.isUser} className="sleuthbot-message-icon">
                {message.isUser ? <PiUserDuotone size={20} /> : <SherlockIconChat src={sherlockLine} alt="Sherlock" />}
              </MessageIcon>
            </Message>
          ))}
          {isLoading && (
            <Message>
              <MessageContent>
                <LoadingBar />
              </MessageContent>
            </Message>
          )}
        </ChatBody>

        <ChatInputContainer className="sleuthbot-chat-input-container">
          <ChatInputOptionsContainer className="sleuthbot-chat-input-options-container">
            <UploadButton onClick={() => fileInputRef.current.click()} disabled={isUploading}>
              {isUploading ? (
                <Spinner />
              ) : (
                <>
                  <PiUploadDuotone size={20} />
                  <UploadText>Upload files</UploadText>
                </>
              )}
            </UploadButton>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} multiple />

            {/* <UploadButton onClick={() => fileInputRef.current.click()} disabled={isUploading}>
              {isUploading ? (
                <Spinner />
              ) : (
                <>
                  <PiFileImageDuotone size={20} />
                  <UploadText>Add images</UploadText>
                </>
              )}
            </UploadButton> */}
          </ChatInputOptionsContainer>
          <ChatInputWrapper>
            <ChatInput
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              placeholder="Type your message... "
            />
            <SendIconButton onClick={handleSend} type="button">
              <PiPaperPlaneTiltDuotone size={20} />
            </SendIconButton>
          </ChatInputWrapper>
        </ChatInputContainer>
      </SleuthBotDrawer>
    </ChatbotContainer>
  );
}

export default SleuthBot;
